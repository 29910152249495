<template>
  <div class="reviews__wrap" style="width: 100%">
    <div class="review-block">
      <div class="review-block__title">

        <div class="review-block__info-user">
          <div class="review-block__info-user-name">
            <p class="text-circe-24 bold">{{review_item.name}}</p>
          </div>
          <p class="text-circe-14 color-gray">{{review_item.date}}</p>
        </div>
      </div>
      <div class="review-block__text">
        <p class="text-circe-16-28"> {{ review_item.review }} </p>
      </div>
      <div class="people-block__btn">
        <button
            type="button"
            class="index-btn index-color"
            @click="openModalEdit(review_item)"
        >
          Изменить
        </button>
        <button
            type="button"
            class="index-btn index-color"
            @click="openModalDeleteReview(review_item)"
        >
          Удалить
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex'
  import { actionTypes as modalActionTypes } from '@/store/modules/modal'

  export default {
    name: "AppReviewItem",
    props: {
      review_item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {

      };
    },
    methods: {
      ...mapActions({
        setModalEditReviewState: modalActionTypes.setIsOpenEditReview,
        setModalDeleteReviewState: modalActionTypes.setIsOpenDeleteReview,
      }),
      openModalDeleteReview(item) {
        this.setModalDeleteReviewState({ isOpen: true, item: item })
      },
      openModalEdit(item) {
        this.setModalEditReviewState({ isOpen: true, item });
      },
    }
  };
</script>

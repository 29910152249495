<template>
<!--  @click="$emit('close')"-->
  <transition name="modal" >
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container mini-width">
          <div class="modal-header">
            <p class="title-modal">Удаление вакансии</p>
            <div class="modal-header__cross" @click="$emit('close')">
              <img src="../../assets/svg/CrossModal.svg" alt="cross" />
            </div>
          </div>
          <div class="modal-body-mini">
            <p class="label-for-input">
              Вы действительно хотите удалить вакансию?
            </p>
          </div>
          <div class="modal-footer">
            <div class="modal-footer__btn">
              <button
                  type="button"
                  class="index-btn lite-color"
                  @click="$emit('close')"
              >
                Нет
              </button>
            </div>
            <div class="modal-footer__btn">
              <button
                  type="button"
                  class="index-btn index-color"
                  @click="onDeleteReviewInfo"
              >
                Да
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { actionTypesVacancies } from '@/store/modules/vacancies'

  export default {
    name: "AppModalDeleteVacancy",
    data() {
      return {};
    },
    props: {
      item_vacancy: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    methods: {
      onDeleteReviewInfo() {
        this.$store
            .dispatch(actionTypesVacancies.deleteVacancy, this.item_vacancy.id)
            .then(() => {
              this.$emit("close");
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
    },
  };
</script>

<style></style>

<template>
  <div class="">
    <AppReviewItem v-for="review in review_list" :key="review.id" :review_item="review"/>
  </div>
</template>

<script>

  import AppReviewItem from '@/components/Rewievs/ReviewsItem.vue'

  export default {
    name: "AppReviewList",
    components: { AppReviewItem },
    props: {
      review_list: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
      };
    }
  };
</script>

import router from "@/router";
const state = {
  // Специалисты
  isOpenAddPeople: false,
  isOpenEditPeople: false,
  isOpenDeletePeople: false,
  editItem: null,
  deleteItem: null,
  // Отзывы
  isOpenAddReview: false,
  isOpenEditReview: false,
  isOpenDeleteReview: false,
  editItemReview: null,
  deleteItemReview: null,
  // Вакансии
  isOpenAddVacancy: false,
  isOpenEditVacancy: false,
  isOpenDeleteVacancy: false,
  editItemVacancy: null,
  deleteItemVacancy: null,
  //Новости
  isOpenDeleteNews: false,
  editItemNews: null,
  deleteItemNews: null,
  //Обращения
  isOpenDeleteAppeals: false,
  deleteItemAppeals: null,
};

export const mutationTypes = {
  // Специалисты
  setIsOpenAddPeople: "[modal] setIsOpenAddPeople",
  setIsOpenEditPeople: "[modal] setIsOpenEditPeople",
  setIsOpenDeletePeople: "[modal] setIsOpenDeletePeople",
  // Отзывы
  setIsOpenAddReview: "[modal] setIsOpenAddReview",
  setIsOpenEditReview: "[modal] setIsOpenEditReview",
  setIsOpenDeleteReview: "[modal] setIsOpenDeleteReview",
  // Вакансии
  setIsOpenAddVacancy: "[modal] setIsOpenAddVacancy",
  setIsOpenEditVacancy: "[modal] setIsOpenEditVacancy",
  setIsOpenDeleteVacancy: "[modal] setIsOpenDeleteVacancy",
  //Новости
  setIsOpenEditNews: "[modal] setIsOpenEditNews",
  setIsOpenDeleteNews: "[modal] setIsOpenDeleteNews",
  setIsOpenDeleteItemNews: "[modal] setIsOpenDeleteItemNews",

  //Обращения
  setIsOpenDeleteAppeals: "[modal] setIsOpenDeleteAppeals",
};

export const actionTypes = {
  // Специалисты
  setIsOpenAddPeople: "[modal] setIsOpenAddPeople",
  setIsOpenEditPeople: "[modal] setIsOpenEditPeople",
  setIsOpenDeletePeople: "[modal] setIsOpenDeletePeople",

  // Отзывы
  setIsOpenAddReview: "[modal] setIsOpenAddReview",
  setIsOpenEditReview: "[modal] setIsOpenEditReview",
  setIsOpenDeleteReview: "[modal] setIsOpenDeleteReview",

  // Вакансии
  setIsOpenAddVacancy: "[modal] setIsOpenAddVacancy",
  setIsOpenEditVacancy: "[modal] setIsOpenEditVacancy",
  setIsOpenDeleteVacancy: "[modal] setIsOpenDeleteVacancy",

  //Новости
  setIsOpenEditNews: "[modal] setIsOpenEditNews",
  setIsOpenDeleteNews: "[modal] setIsOpenDeleteNews",
  setIsOpenDeleteItemNews: '[modal] setIsOpenDeleteItemNews',

  //Обращения
  setIsOpenDeleteAppeals: "[modal] setIsOpenDeleteAppeals",
};

const mutations = {
  // Специалисты
  [mutationTypes.setIsOpenAddPeople](state, isOpen) {
    state.isOpenAddPeople = isOpen;
    toggleBodyScroll(isOpen);
  },
  [mutationTypes.setIsOpenEditPeople](state, { isOpen, item }) {
    state.isOpenEditPeople = isOpen;
    state.editItem = item;
    toggleBodyScroll(isOpen);
  },
  [mutationTypes.setIsOpenDeletePeople](state, { isOpen, item }) {
    state.isOpenDeletePeople = isOpen;
    state.deleteItem = item;
    toggleBodyScroll(isOpen);
  },
  // Отзывы
  [mutationTypes.setIsOpenAddReview](state, isOpen) {
    state.isOpenAddReview = isOpen;
    toggleBodyScroll(isOpen);
  },
  [mutationTypes.setIsOpenEditReview](state, { isOpen, item }) {
    state.isOpenEditReview = isOpen;
    state.editItemReview = item;
    toggleBodyScroll(isOpen);
  },
  [mutationTypes.setIsOpenDeleteReview](state, { isOpen, item }) {
    state.isOpenDeleteReview = isOpen;
    state.deleteItemReview = item;
    toggleBodyScroll(isOpen);
  },
  // Вакансии
  [mutationTypes.setIsOpenAddVacancy](state, isOpen) {
    state.isOpenAddVacancy = isOpen;
    toggleBodyScroll(isOpen);
  },
  [mutationTypes.setIsOpenEditVacancy](state, { isOpen, item }) {
    state.isOpenEditVacancy = isOpen;
    state.editItemVacancy = item;
    toggleBodyScroll(isOpen);
  },
  [mutationTypes.setIsOpenDeleteVacancy](state, { isOpen, item }) {
    state.isOpenDeleteVacancy = isOpen;
    state.deleteItemVacancy = item;
    toggleBodyScroll(isOpen);
  },
  //Новости
  [mutationTypes.setIsOpenEditNews](state, {  item }) {
    state.editItemNews = item;
  },
  [mutationTypes.setIsOpenDeleteNews](state, { isOpen, item }) {
    state.isOpenDeleteNews = isOpen;
    state.deleteItemNews = item;
    toggleBodyScroll(isOpen);
  },
  [mutationTypes.setIsOpenDeleteItemNews](state, { isOpen, item }) {
    state.isOpenDeleteNews = isOpen;
    state.deleteItemNews = item;
    toggleBodyScroll(isOpen);
    // router.push({ name: "News" });
  },
  //Обращения
  [mutationTypes.setIsOpenDeleteAppeals](state, { isOpen, item }) {
    state.isOpenDeleteAppeals = isOpen;
    state.deleteItemAppeals = item;
    toggleBodyScroll(isOpen);
  },

};

const actions = {
  // Специалисты
  [actionTypes.setIsOpenAddPeople]({ commit }, isOpen) {
    commit(mutationTypes.setIsOpenAddPeople, isOpen);
  },
  [actionTypes.setIsOpenEditPeople]({ commit }, { isOpen, item }) {
    commit(mutationTypes.setIsOpenEditPeople, { isOpen, item });
  },
  [actionTypes.setIsOpenDeletePeople]({ commit }, { isOpen, item }) {
    commit(mutationTypes.setIsOpenDeletePeople, { isOpen, item });
  },

  // Отзывы
  [actionTypes.setIsOpenAddReview]({ commit }, isOpen) {
    commit(mutationTypes.setIsOpenAddReview, isOpen);
  },
  [actionTypes.setIsOpenEditReview]({ commit }, { isOpen, item }) {
    commit(mutationTypes.setIsOpenEditReview, { isOpen, item });
  },
  [actionTypes.setIsOpenDeleteReview]({ commit }, { isOpen, item }) {
    commit(mutationTypes.setIsOpenDeleteReview, { isOpen, item });
  },

  // Вакансии
  [actionTypes.setIsOpenAddVacancy]({ commit }, isOpen) {
    commit(mutationTypes.setIsOpenAddVacancy, isOpen);
  },
  [actionTypes.setIsOpenEditVacancy]({ commit }, { isOpen, item }) {
    commit(mutationTypes.setIsOpenEditVacancy, { isOpen, item });
  },
  [actionTypes.setIsOpenDeleteVacancy]({ commit }, { isOpen, item }) {
    commit(mutationTypes.setIsOpenDeleteVacancy, { isOpen, item });
  },
  //Новости
  [actionTypes.setIsOpenEditNews]({ commit }, { item }) {
    commit(mutationTypes.setIsOpenEditNews, { item });
  },
  [actionTypes.setIsOpenDeleteNews]({ commit }, { isOpen, item }) {
    commit(mutationTypes.setIsOpenDeleteNews, { isOpen, item });
  },
  [actionTypes.setIsOpenDeleteItemNews]({ commit }, { isOpen, item }) {
    commit(mutationTypes.setIsOpenDeleteItemNews, { isOpen, item });
  },
  //Обращения
  [actionTypes.setIsOpenDeleteAppeals]({ commit }, { isOpen, item }) {
    commit(mutationTypes.setIsOpenDeleteAppeals, { isOpen, item });
  },
};

function toggleBodyScroll(isOpen) {
  if (isOpen) {
    document.body.classList.add("no-scroll");
  } else {
    document.body.classList.remove("no-scroll");
  }
}

export default {
  state,
  mutations,
  actions,
};

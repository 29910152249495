import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueTheMask from 'vue-the-mask';
import Vuelidate from '@vuelidate/core'; // Обратите внимание, что Vuelidate для Vue 3 требует "@vuelidate/core" и "@vuelidate/validators"
// Импорт Froala редактора и стилей
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/plugins/image.min.js'; // Импорт плагина изображений
import VueFroala from 'vue-froala-wysiwyg';

import './assets/scss/style.scss';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueTheMask);
app.use(Vuelidate);
app.use(VueFroala);

app.config.productionTip = false;
app.config.compatConfig = {
  MODE: 3
}

app.mount('#app');

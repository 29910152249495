<template>
  <div class="grid-container">
   <AppNewsItem v-for="news in news_list" :key="news.id" :news_item="news"/>
  </div>
</template>

<script>

  import AppNewsItem from '@/components/News/NewsItem.vue'

  export default {
    name: "AppNewsList",
    components: { AppNewsItem },

    props: {
      news_list: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
      };
    }
  };
</script>

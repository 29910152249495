<template>
  <div class="grid-item" style="width: 100%">
    <div class="people-block">
      <div class="people-block__img-mini">
        <img :src="people_item.image" alt="Специалист">
      </div>
      <div class="people-block__information">
        <div class="people-block__information-header">
          <p class="text-TTDrugs-regular-20 text-uppercase color-gray">{{people_item.name}}
          </p>
        </div>
        <div class="people-block__information-position">
          <p class="color-black text-TTDrugs-semi-bold-18"><span class="bold">Должность:</span> {{people_item.position}}</p>
        </div>
        <div class="people-block__information-position" v-if="people_item.mainDepartment !== '' && people_item.mainDepartment ">
          <p class="color-black text-TTDrugs-light-18"> <span class="bold">Отделение:</span> {{people_item.mainDepartment.name}}</p>
          <!--        Отделение:-->
        </div>
        <div class="people-block__information-position" v-if="people_item.department !== '' && people_item.department ">
          <p class="color-black text-TTDrugs-light-18"> <span class="bold">Подотделение:</span> {{people_item.department.title}}</p>
          <!--        Отделение:-->
        </div>
        <div class="people-block__information-opening-hours" v-if="people_item.opening !== '' && people_item.opening ">
          <p class="text-circe-20"><span class="text-circe-bold-20">Часы приёма:</span> {{people_item.opening}}</p>
        </div>
        <div class="people-block__btn">
          <button
              type="button"
              class="index-btn index-color"
              @click="openModalEdit(people_item)"
          >
            Изменить
          </button>
          <button
              type="button"
              class="index-btn index-color"
              @click="openModalDeleteUser(people_item)"
          >
            Удалить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex'
  import { actionTypes as modalActionTypes } from '@/store/modules/modal'

  export default {
    name: "AppPeopleItem",
    props: {
      people_item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {

      };
    },
    methods: {
      ...mapActions({
        setModalEditUserState: modalActionTypes.setIsOpenEditPeople,
        setModalDeleteUserState: modalActionTypes.setIsOpenDeletePeople,
      }),
      openModalDeleteUser(item) {
        this.setModalDeleteUserState({ isOpen: true, item: item })
      },
      openModalEdit(item) {
        this.setModalEditUserState({ isOpen: true, item });
      },
    }
  };
</script>

<template>
  <div class="users">
    <Header
        title="Вакансии"
        description="Добавление и изменение вакансий"
    />
    <div class="wrap-loader-2" v-if="isLoading">
      <div id="loader"></div>
    </div>
    <div class="container-page">
      <div class="users__block">
        <div class="users__wrap">
          <div class="users__add">
            <button
                type="button"
                class="index-btn index-color"
                @click="openModalAddVacancy"
            >
              <img
                  src="../assets/svg/plus.svg"
                  alt="plus"
                  class="index-btn__img"
              />
              Добавить вакансию
            </button>
          </div>
        </div>
      </div>
      <div class="users-block-people">
        <AppVacancyList :vacancy_list="allVacancies"/>
      </div>
    </div>

    <AppModalAddVacancy   v-if="isOpenModalAddVacancy"
                          @close="closeModalAddVacancy"/>
    <AppModalEditVacancy  v-if="isOpenModalEditVacancy"
                          @close="closeModalEditVacancy"
                          :item_vacancy="editItemVacancy"/>
    <AppModalDeleteVacancy   v-if="isOpenModalDeleteVacancy"
                             @close="closeModalDeleteVacancy" :item_vacancy="deleteItemVacancy"/>

  </div>
</template>

<script>
  import Header from "../components/Header.vue";
  import { mapState, mapActions } from "vuex";
  import { actionTypes as modalActionTypes } from "@/store/modules/modal";
  import AppModalAddVacancy from '@/components/Modal/ModalAddVacancy.vue'
  import AppModalEditVacancy from '@/components/Modal/ModalEditVacancy.vue'
  import AppModalDeleteVacancy from '@/components/Modal/ModalDeleteVacancy.vue'
  import { actionTypesVacancies } from '@/store/modules/vacancies'
  import AppVacancyList from '@/components/Vacancy/VacancyList.vue'

  export default {
    components: {
      AppVacancyList,

      AppModalDeleteVacancy,
      AppModalEditVacancy,
      AppModalAddVacancy,
      Header,
    },
    name: "AppVacancies",
    data() {
      return {
        searchFIO: "",
        title: "Вакансии",
      };
    },
    methods: {
      ...mapActions({
        openModalAddVacancyAction: modalActionTypes.setIsOpenAddVacancy,
        setModalEditVacancyState: modalActionTypes.setIsOpenEditVacancy,
        setModalDeleteVacancyState: modalActionTypes.setIsOpenDeleteVacancy,
      }),
      openModalAddVacancy() {
        this.openModalAddVacancyAction(true);
      },
      closeModalAddVacancy() {
        this.openModalAddVacancyAction(false);
      },

      closeModalEditVacancy() {
        this.setModalEditVacancyState({ isOpen: false, item: null });
      },

      closeModalDeleteVacancy() {
        this.setModalDeleteVacancyState({ isOpen: false, item: null })
      },
    },
    computed: {
      ...mapState({
        allVacancies: (state) => state.vacancies.allVacancies,
        isLoading: (state) => state.users.isLoading,
        isOpenModalAddVacancy: (state) => state.modal.isOpenAddVacancy,
        isOpenModalEditVacancy: (state) => state.modal.isOpenEditVacancy,
        isOpenModalDeleteVacancy: (state) => state.modal.isOpenDeleteVacancy,
        editItemVacancy: (state) => state.modal.editItemVacancy,
        deleteItemVacancy: (state) => state.modal.deleteItemVacancy,
        token: (state) => state.auth.token,
        username: (state) => state.auth.username,
      }),
    },
    beforeMount() {
      document.title = this.title;
    },
    mounted() {
      if (!this.token) {
        this.$router.push({ name: "Login" });
      }
      this.$store.dispatch(actionTypesVacancies.getVacancies);
    },
  };
</script>

<template>
  <transition name="modal">
<!--    @click="$emit('close')"-->
    <div class="modal-mask" >
      <div class="modal-wrapper">
<!--        @click.stop-->
        <div class="modal-container max-width" >
          <div class="modal-header">
            <p class="title-modal">Добавление отзыва</p>
            <div class="modal-header__cross" @click="$emit('close')">
              <img src="../../assets/svg/CrossModal.svg" alt="cross" />
            </div>
          </div>
          <div class="error" v-if="isSubmitted && vuelidateState.$invalid">
            <p class="error-text">*Заполните все пустые поля</p>
          </div>
          <div class="error-messages">
            <ValidationErrors
                v-if="validationErrors"
                :validation-errors="validationErrors"
            />
          </div>

          <div class="modal-body">
            <!-- Поля формы -->
            <div class="modal-body__wrap-input-column">
              <label for="name" class="label-for-input">ФИО</label>
              <input
                  type="text"
                  name="name"
                  placeholder="Введите ФИО"
                  v-model="vuelidateState.name.$model"
                  class="input-text"
                  :class="{
                  'error-border-input': isSubmitted && vuelidateState.name.$error,
                }"
              />
              <img
                  src="~@/assets/svg/error.svg"
                  alt="error"
                  v-if="isSubmitted && vuelidateState.name.$error"
                  class="wrapValidationInput__error"
              />
            </div>
            <div class="modal-body__wrap-input-column">
              <!-- Поле для ввода отзыва -->
              <label for="review" class="label-for-input">Отзыв</label>
              <textarea
                  name="review"
                  v-model="vuelidateState.review.$model"
                  placeholder="Введите ваш отзыв"
                  class="textarea-input"
                  :class="{
                  'error-border-input': isSubmitted && vuelidateState.review.$error,
                }"
              ></textarea>
              <img
                  src="~@/assets/svg/error.svg"
                  alt="error"
                  v-if="isSubmitted && vuelidateState.review.$error"
                  class="wrapValidationInput__error"
              />
            </div>
          </div>

          <div class="modal-footer">
            <div class="modal-footer__btn">
              <button
                  type="button"
                  class="index-btn lite-color"
                  @click="$emit('close')"
              >
                Отменить
              </button>
            </div>
            <div class="modal-footer__btn">
              <button
                  type="button"
                  class="index-btn index-color"
                  @click="onSubmitUserInfo"
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { reactive, toRefs } from "vue";
  import useVuelidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import { mapState } from "vuex";
  import ValidationErrors from "../ValidationErrors.vue";
  import { actionTypesReview } from "@/store/modules/review";

  export default {
    name: "AppModalAddReview",
    data() {
      return {
        isSubmitted: false,
      };
    },
    setup() {
      const state = reactive({
        name: "",
        review: "",
      });

      const rules = {
        name: { required },
        review: { required },
      };

      const vuelidateState = useVuelidate(rules, state);

      return {
        vuelidateState,
        ...toRefs(state),
      };
    },
    methods: {
      onSubmitUserInfo() {
        this.isSubmitted = true;
        this.vuelidateState.$touch();
        if (this.vuelidateState.$invalid) {
          console.log("не ок");
          return;
        }

        const reviewData = {
          name: this.name,
          review: this.review,
        };

        this.$store
            .dispatch(actionTypesReview.addReview, reviewData)
            .then(() => {
              this.$emit("close");
              this.$store.dispatch(actionTypesReview.getReviews);
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
    },
    components: { ValidationErrors },
    computed: {
      ...mapState({
        validationErrors: (state) => state.users.validationErrors,
      }),
    },
  };
</script>

<style scoped>
/* Ваши стили здесь */
</style>

import axios from "@/api/axios";
const addUser = (formData) => {
  return axios.post(`/employees`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data'  // Указываем заголовок как multipart/form-data
    }
  });
};
const getUsers = () => {
  return axios.get(`/employees`);
};
const deleteUser = (userId) => {
  return axios.post(`/employees/delete?id=${userId}`);
};
const getSearchFio = (fio) => {
  return axios.get(`/employees/search?searchTerm=${fio}`);
}
const editUser = (data) => {
  return axios.post(`/employees/edit`, data);

};

export default {
  addUser,
  getUsers,
  deleteUser,
  editUser,
  getSearchFio,
};

import authAPi from "@/api/auth.js";
import { setItem, getItem } from "@/helpers/persistanceStorage";
import axios from "axios";
import router from "@/router";

const state = {
  isLoading: false, //ожидание запроса
  token: getItem("accessTokenStoreDar") || "",
  isAuthenticated: false, //авторизирован ли пользователь
  validationErrors: null, //ошибки
  username: getItem("infoUserApplicationDar") || null,
};

export const mutationTypes = {
  loginStart: "[auth] loginStart",
  loginSuccess: "[auth] loginSuccess",
  loginFailure: "[auth] loginFailure",

  exitLoginSuccess: "[auth] exit success",
};
export const actionTypes = {
  login: "[auth] login",
  exitLogin: "[auth] exit",
};
export const gettersTypes = {
  done: "done",
};

const mutations = {
  [mutationTypes.loginStart](state) {
    state.isAuthenticated = false;
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.loginSuccess](state, response) {
    console.log(response);
    state.isAuthenticated = true;
    state.token = response.token;
    state.username = response.username;
    state.isLoading = false;
  },
  [mutationTypes.loginFailure](state, payload) {
    state.isAuthenticated = false;
    state.validationErrors = payload.message;
    state.isLoading = false;
  },

  [mutationTypes.exitLoginSuccess](state) {
    state.token = "";
    state.isAuthenticated = false;
  },
};
const actions = {
  [actionTypes.exitLogin](context) {
    context.commit(mutationTypes.exitLoginSuccess);
    localStorage.removeItem("accessTokenStoreDar");
    localStorage.removeItem("infoUserApplicationDar");
    delete axios.defaults.headers.common["Authorization"];
    router.push({ name: "Login" });
  },
  [actionTypes.login](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.loginStart);
      authAPi
        .login(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            setItem("accessTokenStoreDar", response.data.result.token);
            context.commit(mutationTypes.loginSuccess, response.data.result);
            setItem("infoUserApplicationDar", response.data.result.username);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.loginFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.loginFailure, err);
          console.log("ошибка", err);
        });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};

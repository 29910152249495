import axios from "@/api/axios";

const getUnderDepartment = (id) => {
  return axios.get(`/sub-department/${id}`);
};
const getDepartment =() => {
  return axios.get(`/department`);
}

export default {
  getUnderDepartment,
  getDepartment,
};

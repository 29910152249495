import { createStore } from 'vuex';
import auth from '@/store/modules/auth';
import users from '@/store/modules/users';
import modal from '@/store/modules/modal';
import department from '@/store/modules/department';
import review from '@/store/modules/review';
import vacancies from '@/store/modules/vacancies';
import news from '@/store/modules/news';
import appeals from '@/store/modules/appeals';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    users,
    modal,
    department,
    review,
    vacancies,
    news,
    appeals
  },
});

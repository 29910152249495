<template>
  <transition name="modal">
<!--    @click="$emit('close')"-->
    <div class="modal-mask" >
      <div class="modal-wrapper">
<!--        @click.sto-->
        <div class="modal-container max-width-1100" p>
          <div class="modal-header">
            <p class="title-modal">Изменение данных о вакансии</p>
            <div class="modal-header__cross" @click="$emit('close')">
              <img src="../../assets/svg/CrossModal.svg" alt="cross" />
            </div>
          </div>
          <div class="error" v-if="isSubmitted && vuelidateState.$invalid">
            <p class="error-text">*Заполните все пустые поля</p>
          </div>
          <div class="error-messages">
            <ValidationErrors
                v-if="validationErrors"
                :validation-errors="validationErrors"
            />
          </div>
          <form id="modalEditVacancy">
            <div class="modal-body">
              <div class="modal-body__wrap-input-column">
                <label for="title" class="label-for-input">Название вакансии</label>
                <input
                    type="text"
                    name="title"
                    placeholder="Введите название вакансии"
                    v-model="vuelidateState.title.$model"
                    class="input-text"
                    :class="{
                    'error-border-input': isSubmitted && vuelidateState.title.$error,
                  }"
                />
                <img
                    src="~@/assets/svg/error.svg"
                    alt="error"
                    v-if="isSubmitted && vuelidateState.title.$error"
                    class="wrapValidationInput__error"
                />
              </div>
              <div class="modal-body__wrap-input-column">
                <label for="salary" class="label-for-input">Зарплата</label>
                <input
                    type="text"
                    name="salary"
                    placeholder="Введите зарплату"
                    v-model="vuelidateState.salary.$model"
                    class="input-text"
                    :class="{
                    'error-border-input': isSubmitted && vuelidateState.salary.$error,
                  }"
                />
                <img
                    src="~@/assets/svg/error.svg"
                    alt="error"
                    v-if="isSubmitted && vuelidateState.salary.$error"
                    class="wrapValidationInput__error"
                />
              </div>
              <!-- Поле для ввода обязанностей -->
              <div class="modal-body__wrap-input-column">
                <label for="duties" class="label-for-input">Обязанности</label>
                <div v-for="(duty, index) in duties" :key="index" class="duty-item">
                  <input
                      type="text"
                      v-model="duties[index]"
                      class="input-text"
                      placeholder="Введите обязанность"
                  />
                  <div class="duty-item__delete">
                    <button type="button" @click="removeDuty(index)">
                      <img src="../../assets/svg/CrossModal.svg" alt="cross" />
                    </button>
                  </div>
                </div>
                <button class="index-btn lite-color" type="button" @click="addDuty">
                  Добавить обязанность
                </button>
              </div>
              <!-- Поле для ввода требований -->
              <div class="modal-body__wrap-input-column">
                <label for="requirements" class="label-for-input">Требования</label>
                <div v-for="(requirement, index) in requirements" :key="index" class="requirement-item">
                  <input
                      type="text"
                      v-model="requirements[index]"
                      class="input-text"
                      placeholder="Введите требование"
                  />
                  <div class="requirement-item__delete">
                    <button type="button" @click="removeRequirement(index)">
                      <img src="../../assets/svg/CrossModal.svg" alt="cross" />
                    </button>
                  </div>
                </div>
                <button class="index-btn lite-color" type="button" @click="addRequirement">
                  Добавить требование
                </button>
              </div>
              <!-- Поле для ввода условий -->
              <div class="modal-body__wrap-input-column">
                <label for="conditions" class="label-for-input">Условия</label>
                <div v-for="(condition, index) in conditions" :key="index" class="condition-item">
                  <input
                      type="text"
                      v-model="conditions[index]"
                      class="input-text"
                      placeholder="Введите условие"
                  />
                  <div class="condition-item__delete">
                    <button type="button" @click="removeCondition(index)">
                      <img src="../../assets/svg/CrossModal.svg" alt="cross" />
                    </button>
                  </div>
                </div>
                <button class="index-btn lite-color" type="button" @click="addCondition">
                  Добавить условие
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <div class="modal-footer__btn">
                <button
                    type="button"
                    class="index-btn lite-color"
                    @click="$emit('close')"
                >
                  Отменить
                </button>
              </div>
              <div class="modal-footer__btn">
                <button
                    @click="saveInfoVacancy"
                    type="button"
                    class="index-btn index-color"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { reactive, toRefs } from "vue";
  import useVuelidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import { actionTypesVacancies } from "@/store/modules/vacancies";
  import { mapState } from "vuex";
  import ValidationErrors from "../ValidationErrors.vue";

  export default {
    name: "AppModalEditVacancy",
    props: {
      item_vacancy: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        isSubmitted: false,
        duties: this.item_vacancy.duties || [""],
        requirements: this.item_vacancy.requirements || [""],
        conditions: this.item_vacancy.conditions || [""],
      };
    },
    setup(props) {
      const state = reactive({
        title: props.item_vacancy.title || "",
        salary: props.item_vacancy.salary || "",
      });

      const rules = {
        title: { required },
        salary: { required },
      };

      const vuelidateState = useVuelidate(rules, state);

      return {
        vuelidateState,
        ...toRefs(state),
      };
    },
    methods: {
      saveInfoVacancy() {
        this.isSubmitted = true;
        this.vuelidateState.$touch();
        if (this.vuelidateState.$invalid) {
          return console.log("не ок");
        }

        // Создание объекта вакансии
        const vacancyData = {
          id: this.item_vacancy.id,
          title: this.vuelidateState.title.$model,
          salary: this.vuelidateState.salary.$model,
          duties: this.duties.filter((duty) => duty.trim() !== ""),
          requirements: this.requirements.filter((requirement) => requirement.trim() !== ""),
          conditions: this.conditions.filter((condition) => condition.trim() !== ""),
        };

        // Отправка данных через store
        this.$store
            .dispatch(actionTypesVacancies.editVacancy, vacancyData)
            .then(() => {
              this.$emit("close");
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      addDuty() {
        this.duties.push("");
      },
      removeDuty(index) {
        this.duties.splice(index, 1);
      },
      addRequirement() {
        this.requirements.push("");
      },
      removeRequirement(index) {
        this.requirements.splice(index, 1);
      },
      addCondition() {
        this.conditions.push("");
      },
      removeCondition(index) {
        this.conditions.splice(index, 1);
      },
    },
    components: { ValidationErrors },
    computed: {
      ...mapState({
        validationErrors: (state) => state.vacancies.validationErrors,
      }),
    },
  };
</script>

<style scoped>
/* Ваши стили здесь */
</style>

import axios from "@/api/axios";

const getAppeals = () => {
  return axios.get(`/petitions`);
};

const deleteAppeal= (vacancyId) => {
  return axios.post(`/petitions/delete/${vacancyId}`);
};

const postStatistics = (data) => {
  return axios.post(`/statistics/save`, data);
};
const getStatistics = () => {
  return axios.get(`/statistics`);
};

export default {
  getAppeals,
  deleteAppeal,
  postStatistics,
  getStatistics
};

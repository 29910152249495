<template>
  <transition name="modal">
<!--    @click="$emit('close')"-->
    <div class="modal-mask" >
      <div class="modal-wrapper">
<!--        @click.stop-->
        <div class="modal-container max-width" >

          <div class="modal-header">
            <p class="title-modal">Изменение данных о специалисте</p>
            <div class="modal-header__cross" @click="$emit('close')">
              <img src="../../assets/svg/CrossModal.svg" alt="cross" />
            </div>
          </div>
          <div class="error" v-if="isSubmitted && vuelidateState.$invalid">
            <p class="error-text">*Заполните все пустые поля</p>
          </div>
          <div class="error-messages">
            <ValidationErrors
                v-if="validationErrors"
                :validation-errors="validationErrors"
            />
          </div>
          <form id="modalEditUser">
            <div class="modal-body">
              <div class="modal-body__wrap-input">
                <label for="surname" class="label-for-input">Фамилия</label>
                <input
                    type="text"
                    name="surname"
                    v-model="putData.surname"
                    placeholder="Введите фамилию"
                    class="input-text"
                    :class="{
                    'error-border-input':
                      isSubmitted && vuelidateState.putData.surname.$error,
                  }"
                />
                <img
                    src="~@/assets/svg/error.svg"
                    alt="error"
                    v-if="isSubmitted && vuelidateState.putData.surname.$error"
                    class="wrapValidationInput__error"
                />
              </div>
              <div class="modal-body__wrap-input">
                <label for="name" class="label-for-input">Имя</label>
                <input
                    type="text"
                    name="name"
                    placeholder="Введите имя"
                    v-model="putData.name"
                    class="input-text"
                    :class="{
                    'error-border-input': isSubmitted && vuelidateState.putData.name.$error,
                  }"
                />
                <img
                    src="~@/assets/svg/error.svg"
                    alt="error"
                    v-if="isSubmitted && vuelidateState.putData.name.$error"
                    class="wrapValidationInput__error"
                />
              </div>
              <div class="modal-body__wrap-input">
                <label for="lastName" class="label-for-input">Отчество</label>
                <input
                    type="text"
                    name="lastName"
                    v-model="putData.lastName"
                    placeholder="Введите отчество"
                    class="input-text"
                    :class="{
                    'error-border-input':
                      isSubmitted && vuelidateState.putData.lastName.$error,
                  }"
                />
                <img
                    src="~@/assets/svg/error.svg"
                    alt="error"
                    v-if="isSubmitted && vuelidateState.putData.lastName.$error"
                    class="wrapValidationInput__error"
                />
              </div>
              <div class="modal-body__wrap-input">
                <label for="position" class="label-for-input">Должность</label>
                <input
                    type="text"
                    name="position"
                    v-model="putData.position"
                    placeholder="Введите должность"
                    class="input-text"
                    :class="{
                    'error-border-input':
                      isSubmitted && vuelidateState.putData.position.$error,
                  }"
                />
                <img
                    src="~@/assets/svg/error.svg"
                    alt="error"
                    v-if="isSubmitted && vuelidateState.putData.position.$error"
                    class="wrapValidationInput__error"
                />
              </div>
              <!-- Поле выбора отдела -->
              <div class="modal-body__wrap-input">
                <label for="department" class="label-for-input">Назначить роль</label>
                <select
                    name="employeeRole"
                    id=""
                    class="select"
                    v-model="putData.employeeRole"
                    :class="{
                  'error-border-input': isSubmitted && vuelidateState.putData.employeeRole.$error,
                }"
                >
                  <option value="EMPLOYEE">Врач</option>
                  <option value="MANAGEMENT">Руководство</option>
                  <option value="CHIEF_MEDICAL_OFFICER">Главный врач</option>
                </select>
              </div>
              <div class="modal-body__wrap-input">
                <label for="position" class="label-for-input">Часы работы (*при необходимости)</label>
                <input
                    type="text"
                    name="openingHours"
                    v-model="putData.openingHours"
                    placeholder="Введите часы работы"
                    class="input-text"
                    :class="{
                  'error-border-input': isSubmitted && vuelidateState.putData.openingHours.$error,
                }"
                />
                <img
                    src="~@/assets/svg/error.svg"
                    alt="error"
                    v-if="isSubmitted && vuelidateState.putData.openingHours.$error"
                    class="wrapValidationInput__error"
                />
              </div>
              <!-- Поле выбора отдела -->
              <div class="modal-body__wrap-input">
                <label for="department" class="label-for-input">Назначить отделение</label>
                <select
                    name="department"
                    class="select"
                    v-model="putData.department"
                    @change="fetchSubDepartmentsChange"
                    :class="{
                    'error-border-input': isSubmitted && vuelidateState.putData.department.$error,
                  }"
                >
                  <option v-for="item in departments" :key="item.id" :value="item">{{ item.name }}</option>
                </select>
              </div>

              <!-- Поле выбора подотдела -->
              <div class="modal-body__wrap-input" v-if="putData.subDepartment !== null || underDepartments.length !== 0">
                <label for="subDepartment" class="label-for-input">Назначить подотделение</label>
                <select
                    name="subDepartment"
                    class="select"
                    v-model="putData.subDepartment"
                    :class="{
                    'error-border-input': isSubmitted && vuelidateState.putData.subDepartment.$error,
                  }"
                >
                  <option v-for="item in underDepartments" :key="item.id" :value="item">{{ item.title }}</option>
                </select>
              </div>

              <!-- Поле выбора фотографии -->
              <div class="modal-body__wrap-input-column">
                <label for="photo" class="label-for-input">Прикрепленная фотография</label>

                <!-- Кастомный input -->
                <div class="custom-file-input">
                  <label for="file-upload" class="custom-file-label">
                    {{ photoName.value || "Выберите файл" }}
                  </label>
                  <input
                      type="file"
                      id="file-upload"
                      name="photo"
                      @change="onFileChange"
                      accept="image/*"
                      class="input-file-hidden"
                  />
                </div>
              </div>

              <!-- Миниатюра выбранной фотографии -->
              <div class="modal-body__wrap-input-column" v-if="photoPreview">
                <label class="label-for-input">Предварительный просмотр</label>
                <div class="photo-preview__wrap">
                  <img :src="photoPreview" alt="Preview" class="photo-preview" />
                </div>
              </div>
            </div>
            <div class="" v-if="isLoading">
              <p class="color-green center">Загружаем изменения, подождите..</p>
            </div>
            <div class="modal-footer">
              <div class="modal-footer__btn">
                <button
                    type="button"
                    class="index-btn lite-color"
                    @click="$emit('close')"
                >
                  Отменить
                </button>
              </div>
              <div class="modal-footer__btn">
                <button
                    @click="saveInfoUser"
                    type="button"
                    class="index-btn index-color"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { reactive, ref, toRefs } from 'vue';
  import useVuelidate from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  import { actionTypes } from "@/store/modules/users";
  import { actionTypesDepartment } from "@/store/modules/department";
  import { mapState } from "vuex";
  import ValidationErrors from "../ValidationErrors.vue";

  export default {
    name: "AppModalEditUser",
    props: {
      item_user: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    setup(props) {
      // Функция для разделения полного имени на части
      const getNamePart = (fullName, part) => {
        const nameParts = fullName.split(' ');
        if (part === 'first') return nameParts[0] || '';
        if (part === 'middle') return nameParts[1] || '';
        if (part === 'last') return nameParts[2] || '';
        return '';
      };

      // Инициализация данных с использованием функции getNamePart
      const putData = reactive({
        surname: getNamePart(props.item_user.name, 'first'),
        name: getNamePart(props.item_user.name, 'middle'),
        lastName: getNamePart(props.item_user.name, 'last'),
        position: props.item_user.position || "",
        openingHours: props.item_user.openingHours || "",
        employeeRole: props.item_user.employeeRole || "",
        department: props.item_user.mainDepartment || "",
        subDepartment: props.item_user.department || null,
      });

      // Переменные для хранения файла изображения и его имени
      const photo = ref(null);
      const photoName = ref('');

      // Для отображения уже выбранного изображения
      const photoPreview = ref(props.item_user.image || null);

      const rules = {
        putData: {
          name: { required },
          surname: { required },
          lastName: { required },
          position: { required },
          department: { required },
          employeeRole: {required},
          openingHours: {},
          subDepartment: {},
        },
      };

      const vuelidateState = useVuelidate(rules, { putData });

      const onFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          photo.value = file;
          photoName.value = file.name;
          photoPreview.value = URL.createObjectURL(file);
        }
      };

      return {
        vuelidateState,
        putData,
        photo,
        photoName,
        photoPreview,
        onFileChange,
        ...toRefs(putData),
      };
    },
    components: { ValidationErrors },
    computed: {
      ...mapState({
        validationErrors: (state) => state.users.validationErrors,
        isLoading: (state) => state.users.isLoading,
        departments: (state) => state.department.departments,
        underDepartments: (state) => state.department.underDepartments,
      }),
    },
    methods: {
      getDepartment() {
        this.$store
            .dispatch(actionTypesDepartment.getDepartment)
            .then(() => {
              this.initializeDepartment(); // Инициализация департамента после загрузки
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      initializeDepartment() {
        const foundDepartment = this.departments.find(
            (dept) => dept.name === this.item_user.mainDepartment.name
        );
        if (foundDepartment) {
          this.putData.department = foundDepartment;

          // Если department не null, отправляем запрос на подотделения
          if (this.item_user.department) {
            this.fetchSubDepartments(foundDepartment.id);
          }
        }
      },
      fetchSubDepartmentsChange() {
        this.$store
            .dispatch(
                actionTypesDepartment.getUnderDepartment,
                this.putData.department.id
            )
            .then(() => {
              this.putData.subDepartment = null;
            })
            .catch((error) => {
              console.error("Ошибка при получении подотделений:", error);
            });
      },
      fetchSubDepartments(departmentId) {
        if (departmentId) {
          this.$store
              .dispatch(actionTypesDepartment.getUnderDepartment, departmentId)
              .then(() => {
                // Найти нужное подотделение после получения данных
                const foundSubDepartment = this.underDepartments.find(
                    (subDept) => subDept.title === this.item_user.department.name
                );
                if (foundSubDepartment) {
                  this.putData.subDepartment = foundSubDepartment;
                }
              })
              .catch((error) => {
                console.error("Ошибка при получении подотделений:", error);
              });
        }
      },
      saveInfoUser() {
        this.isSubmitted = true;
        this.vuelidateState.$touch();
        if (this.vuelidateState.$invalid) {
          return console.log("не ок");
        } else {
          const formData = new FormData();

          // Создание JSON объекта для employee
          const employeeData = {
            id: this.item_user.id,
            name: this.putData.name,
            firstName: this.putData.surname,
            lastName: this.putData.lastName,
            medicalDepartmentId: this.putData.department.id,
            position: this.putData.position,
            employeeRole: this.putData.employeeRole,
            openingHours: this.putData.openingHours,
            medicalSubDepartmentId:
                this.putData.subDepartment?.medicalSubDepartmentId || null,
          };
          // console.log(employeeData)
          // Добавление JSON строки в formData
          formData.append("employee", JSON.stringify(employeeData));

          // Добавление фотографии в formData
          if (this.photo) {
            formData.append("image", this.photo);
          }

          // Отправка данных через store
          this.$store
              .dispatch(actionTypes.editUser, formData)
              .then(() => {
                this.$emit("close");
              })
              .catch((er) => {
                console.log("Ошибка", er);
              });
        }
      },
    },
    mounted() {
      this.getDepartment(); // Загружаем департаменты и инициализируем данные
    },
  };
</script>

<style scoped>
.photo-preview {
  max-width: 150px;
  max-height: 150px;
  border-radius: 12px;
}
.photo-preview__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Скрытый стандартный input */
.input-file-hidden {
  display: none;
}

/* Стилизация кастомного label */
.custom-file-input {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-file-label {
  display: block;
  padding: 14px 18px 14px 14px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  color: #333;
  font-weight: 500;
}

.custom-file-label:hover {
  background-color: #e1e1e1;
}
</style>

<template>
  <div id="app" class="app">
    <Sidebar v-if="$route.meta.Sidebar" />
    <router-view />
  </div>
</template>

<script>
  import Sidebar from "./components/Sidebar.vue";

  export default {
    name: "App",
    components: { Sidebar },
  };
</script>

<style lang="scss">
.app {
  display: flex;
  main {
    flex: 1 1 0;
    padding: 2rem;
    @media (max-width: 1024px) {
      padding-left: 6rem;
    }
  }
}
</style>

import newsApi from "@/api/news.js";

const state = {
  isLoading: false, // ожидание запроса
  validationErrors: null, // ошибки
  allNews: [],
  itemNews: {},
};

export const mutationTypes = {
  addNewsStart: "[news] addNewsStart",
  addNewsSuccess: "[news] addNewsSuccess",
  addNewsFailure: "[news] addNewsFailure",

  getNewsStart: "[news] getNewsStart",
  getNewsSuccess: "[news] getNewsSuccess",
  getNewsFailure: "[news] getNewsFailure",

  getNewsItemStart: "[news] getNewsItemStart",
  getNewsItemSuccess: "[news] getNewsItemSuccess",
  getNewsItemFailure: "[news] getNewsItemFailure",

  deleteNewsStart: "[news] deleteNewsStart",
  deleteNewsSuccess: "[news] deleteNewsSuccess",
  deleteNewsFailure: "[news] deleteNewsFailure",

  editNewsStart: "[news] editNewsStart",
  editNewsSuccess: "[news] editNewsSuccess",
  editNewsFailure: "[news] editNewsFailure",
};

export const actionTypesNews = {
  addNews: "[news] addNews",
  getNews: "[news] getNews",
  getNewsItem: '[news] getNewsItem',
  deleteNews: "[news] deleteNews",
  editNews: "[news] editNews",
};

const mutations = {
  // добавление новой новости
  [mutationTypes.addNewsStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.addNewsSuccess](state, item) {
    state.isLoading = false;
    state.allNews.unshift(item);
  },
  [mutationTypes.addNewsFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  // удаление
  [mutationTypes.deleteNewsStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.deleteNewsSuccess](state, data) {
    state.isLoading = false;
    state.allNews = data;
  },
  [mutationTypes.deleteNewsFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  // редактирование
  [mutationTypes.editNewsStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.editNewsSuccess](state, item) {
    state.isLoading = false;
    const index = state.allNews.findIndex(news => news.id === item.id);
    if (index !== -1) {
      // Если новость с таким ID найдена, заменяем её
      state.allNews.splice(index, 1, item);
    }
  },
  [mutationTypes.editNewsFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  // получение всех новостей
  [mutationTypes.getNewsStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getNewsSuccess](state, data) {
    state.isLoading = false;
    state.allNews = data;
  },
  [mutationTypes.getNewsFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  //Получение новостей по id
  [mutationTypes.getNewsItemStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getNewsItemSuccess](state, data) {
    state.isLoading = false;
    state.itemNews = data;
  },
  [mutationTypes.getNewsItemFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },

};

const actions = {
  // добавление
  [actionTypesNews.addNews](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addNewsStart);
      newsApi
          .addNews(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.addNewsSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.addNewsFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.addNewsFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // получение
  [actionTypesNews.getNews](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getNewsStart);
      newsApi
          .getNews()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.getNewsSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.getNewsFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getNewsFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // Получение новости по ID
  [actionTypesNews.getNewsItem](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getNewsItemStart);
      newsApi
          .getNewsItem(id)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getNewsItemSuccess,
                  response.data.result
              );
              resolve(response.data);
            } else {
              context.commit(mutationTypes.getNewsItemFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getNewsItemFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // удаление
  [actionTypesNews.deleteNews](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteNewsStart);
      newsApi
          .deleteNews(id)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.deleteNewsSuccess,
                  response.data.result
              );
              resolve(response.data);
            } else {
              context.commit(mutationTypes.deleteNewsFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.deleteNewsFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // редактирование
  [actionTypesNews.editNews](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editNewsStart);
      newsApi
          .editNews(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.editNewsSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.editNewsFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.editNewsFailure, err);
            console.log("ошибка", err);
          });
    });
  },
};

export default {
  state,
  mutations,
  actions,
};

<template>
  <div class="users">
    <Header
        title="Отзывы"
        description="Добавление и изменение отзывов"
    />
    <div class="wrap-loader-2" v-if="isLoading">
      <div id="loader"></div>
    </div>
    <div class="container-page">
      <div class="users__block">
        <div class="users__wrap">
          <div class="users__add">
            <button
                type="button"
                class="index-btn index-color"
                @click="openModalAddReview"
            >
              <img
                  src="../assets/svg/plus.svg"
                  alt="plus"
                  class="index-btn__img"
              />
              Добавить отзыв
            </button>
          </div>
        </div>
      </div>
      <div class="users-block-people">
        <AppReviewList :review_list="allReviews"/>
      </div>
    </div>

    <AppModalAddReview   v-if="isOpenModalAddReview"
                         @close="closeModalAddReview"/>
   <AppModalEditReview  v-if="isOpenModalEditReview"
                        @close="closeModalEditReview"
                        :item_review="editItemReview"/>
    <AppModalDeleteReview   v-if="isOpenModalDeleteReview"
                            @close="closeModalDeleteReview" :item_review="deleteItemReview"/>

  </div>
</template>

<script>
  import Header from "../components/Header.vue";
  import { mapState, mapActions } from "vuex";
  import { actionTypes as modalActionTypes } from "@/store/modules/modal";
  import AppModalAddReview from '@/components/Modal/ModalAddReview.vue'
  import AppModalEditReview from '@/components/Modal/ModalEditReview.vue'
  import AppModalDeleteReview from '@/components/Modal/ModalDeleteReview.vue'
  import { actionTypesReview } from '@/store/modules/review'
  import AppReviewList from '@/components/Rewievs/ReviewsList.vue'

  export default {
    components: {

      AppReviewList,
      AppModalDeleteReview,
      AppModalEditReview,
      AppModalAddReview,
      Header,
    },
    name: "AppReviews",
    data() {
      return {
        searchFIO: "",
        title: "Отзывы",
      };
    },
    props: {

    },
    methods: {
      ...mapActions({
        openModalAddReviewAction: modalActionTypes.setIsOpenAddReview,
        setModalEditReviewState: modalActionTypes.setIsOpenEditReview,
        setModalDeleteReviewState: modalActionTypes.setIsOpenDeleteReview,
      }),
      openModalAddReview() {
        this.openModalAddReviewAction(true);
      },
      closeModalAddReview() {
        this.openModalAddReviewAction(false);
      },
      openModalEditReview(item) {
        this.setModalEditReviewState({ isOpen: true, item });
      },
      closeModalEditReview() {
        this.setModalEditReviewState({ isOpen: false, item: null });
      },
      openModalDeleteReview(item) {
        this.setModalDeleteReviewState({ isOpen: true, item: item })
      },
      closeModalDeleteReview() {
        this.setModalDeleteReviewState({ isOpen: false, item: null })
      },

    },
    computed: {
      ...mapState({
        allReviews: (state) => state.review.allReviews,
        isLoading: (state) => state.users.isLoading,
        isOpenModalAddReview: (state) => state.modal.isOpenAddReview,
        isOpenModalEditReview: (state) => state.modal.isOpenEditReview,
        isOpenModalDeleteReview: (state) => state.modal.isOpenDeleteReview,
        editItemReview: (state) => state.modal.editItemReview,
        deleteItemReview: (state) => state.modal.deleteItemReview,
        token: (state) => state.auth.token,
        username: (state) => state.auth.username,
      }),

    },
    beforeMount() {
      document.title = this.title;
    },
    mounted() {
      if (!this.token) {
        this.$router.push({ name: "Login" });
      }
      this.$store.dispatch(actionTypesReview.getReviews);
    },
  };
</script>

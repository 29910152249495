import reviewsApi from "@/api/review.js";

const state = {
  isLoading: false, //ожидание запроса
  validationErrors: null, //ошибки
  allReviews: [],
};

export const mutationTypes = {
  addReviewStart: "[reviews] addReviewStart",
  addReviewSuccess: "[reviews] addReviewSuccess",
  addReviewFailure: "[reviews] addReviewFailure",

  getReviewsStart: "[reviews] getReviewsStart",
  getReviewsSuccess: "[reviews] getReviewsSuccess",
  getReviewsFailure: "[reviews] getReviewsFailure",

  deleteReviewStart: "[reviews] deleteReviewStart",
  deleteReviewSuccess: "[reviews] deleteReviewSuccess",
  deleteReviewFailure: "[reviews] deleteReviewFailure",

  editReviewStart: "[reviews] editReviewStart",
  editReviewSuccess: "[reviews] editReviewSuccess",
  editReviewFailure: "[reviews]  editReviewFailure",

};
export const actionTypesReview = {
  addReview: "[reviews] addReview",
  getReviews: "[reviews] getReviews",
  deleteReview: "[reviews] deleteReview",
  editReview: "[reviews]  editReview",

};

const mutations = {
  //добавление нового пользователя
  [mutationTypes.addReviewStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.addReviewSuccess](state, item) {
    state.isLoading = false;
    state.allReviews.unshift(item);

  },
  [mutationTypes.addReviewFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  //удаление
  [mutationTypes.deleteReviewStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.deleteReviewSuccess](state, data) {
    state.isLoading = false;
    state.allReviews = data;

  },
  [mutationTypes.deleteReviewFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  //редактирование
  [mutationTypes.editReviewStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.editReviewSuccess](state, item) {
    state.isLoading = false;
    const index = state.allReviews.findIndex(review => review.id === item.id);
    if (index !== -1) {
      // Если отзыв с таким ID найден, заменяем его
      state.allReviews.splice(index, 1, item);
    }

  },
  [mutationTypes.editReviewFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  //получение всех пользователей
  [mutationTypes.getReviewsStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getReviewsSuccess](state, data) {
    state.isLoading = false;
    state.allReviews = data;
  },
  [mutationTypes.getReviewsFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },

};
const actions = {
  //добавление
  [actionTypesReview.addReview](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addReviewStart);
      reviewsApi
          .addReview(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.addReviewSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.addReviewFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.addReviewFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  //получение
  [actionTypesReview.getReviews](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getReviewsStart);
      reviewsApi
          .getReviews()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.getReviewsSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.getReviewsFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getReviewsFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  //удаление
  [actionTypesReview.deleteReview](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteReviewStart);
      reviewsApi
          .deleteReview(id)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.deleteReviewSuccess,
                  response.data.result
              );
              resolve(response.data);
            } else {
              context.commit(mutationTypes.deleteReviewFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.deleteReviewFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // редактирование
  [actionTypesReview.editReview](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editReviewStart);
      reviewsApi
          .editReview(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.editReviewSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.editReviewFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.editReviewFailure, err);
            console.log("ошибка", err);
          });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};

<template>
  <div class="reviews__wrap" style="width: 100%">
    <div class="vacancy__item">
      <div class="vacancy__body">
        <p class="text-TTDrugs-regular-24">{{ vacancy_item.title }}</p>
        <p class="vacancy__salary text-circe-bold-18 color-gray">{{ vacancy_item.salary }}</p>
        <div class="vacancy__section" v-if="vacancy_item.duties && vacancy_item.duties.length">
          <p class="vacancy__section-title text-TTDrugs-bold-20 color-gray">Обязанности</p>
          <ul>
            <li v-for="duty in vacancy_item.duties" :key="duty"  class="text-circe-16">{{ duty }}</li>
          </ul>
        </div>
        <div class="vacancy__section" v-if="vacancy_item.requirements && vacancy_item.requirements.length">
          <p class="vacancy__section-title  text-TTDrugs-bold-20 color-gray">Требования</p>
            <ul>
              <li v-for="requirement in vacancy_item.requirements" :key="requirement" class="text-circe-16">{{ requirement }}</li>
            </ul>
        </div>
        <div class="vacancy__section" v-if="vacancy_item.conditions && vacancy_item.conditions.length">
          <p class="vacancy__section-title  text-TTDrugs-bold-20 color-gray">Условия</p>
          <ul>
            <li v-for="condition in vacancy_item.conditions" :key="condition"  class="text-circe-16">{{ condition }}</li>
          </ul>
        </div>
      </div>
      <div class="people-block__btn">
        <button
            type="button"
            class="index-btn index-color"
            @click="openModalEdit(vacancy_item)"
        >
          Изменить
        </button>
        <button
            type="button"
            class="index-btn index-color"
            @click="openModalDeleteVacancy(vacancy_item)"
        >
          Удалить
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex'
  import { actionTypes as modalActionTypes } from '@/store/modules/modal'

  export default {
    name: "AppVacancyItem",
    props: {
      vacancy_item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {

      };
    },
    methods: {
      ...mapActions({
        setModalEditVacancyState: modalActionTypes.setIsOpenEditVacancy,
        setModalDeleteVacancyState: modalActionTypes.setIsOpenDeleteVacancy,
      }),
      openModalDeleteVacancy(item) {
        this.setModalDeleteVacancyState({ isOpen: true, item: item })
      },
      openModalEdit(item) {
        this.setModalEditVacancyState({ isOpen: true, item });
      },
    }
  };
</script>

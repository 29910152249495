import axios from "@/api/axios";

const addNews = (data) => {
  return axios.post(`/news/save`, data);
};

const getNews = () => {
  return axios.get(`/news`);
};

const deleteNews = (newsId) => {
  return axios.post(`/news/delete/${newsId}`);
};

const editNews = (data) => {
  return axios.post(`/news/edit`, data);
};
const getNewsItem = (id) => {
  return axios.get(`/news/${id}`);
}


export default {
  addNews,
  getNews,
  deleteNews,
  editNews,
  getNewsItem
};

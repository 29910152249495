import { createRouter, createWebHistory } from 'vue-router';
import Users from '@/views/Users.vue';
import Login from '@/views/Login.vue';
import Reviews from '@/views/Reviews.vue';
import Vacancies from '@/views/Vacancies.vue';
import News from '@/views/News.vue';
import AppItemNewsDetail from  '@/views/NewsItemMoreDetail.vue'
import AddNewsFlo from '@/views/AddNewsFlo.vue';
import Appeals from '@/views/Appeals.vue'
import Statistics from '@/views/Statistics.vue'

const routes = [
  {
    path: '/',
    name: 'Users',
    component: Users,
    meta: {
      Sidebar: true,
    },
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: Reviews,
    meta: {
      Sidebar: true,
    },
  },
  {
    path: '/vacancies',
    name: 'Vacancies',
    component: Vacancies,
    meta: {
      Sidebar: true,
    },
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      Sidebar: true,
    },
  },
  {
    path: '/news/:id',
    name: 'ItemNewsDetail',
    component: AppItemNewsDetail,
    meta: {
      Sidebar: true,
    },
  },
  {
    path: '/news/add',
    name: 'AddNews',
    component: AddNewsFlo,
    meta: {
      Sidebar: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      Sidebar: false,
    },
  },
  {
    path: '/appeals',
    name: 'Appeals',
    component: Appeals,
    meta: {
      Sidebar: true,
    },
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics,
    meta: {
      Sidebar: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

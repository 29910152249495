<template>
  <transition name="modal">
<!--    @click="$emit('close')"-->
    <div class="modal-mask" >
      <div class="modal-wrapper">
<!--        @click.stop-->
        <div class="modal-container max-width" >
          <div class="modal-header">
            <p class="title-modal">Добавление специалиста</p>
            <div class="modal-header__cross" @click="$emit('close')">
              <img src="../../assets/svg/CrossModal.svg" alt="cross" />
            </div>
          </div>
          <div class="error" v-if="isSubmitted && vuelidateState.$invalid">
            <p class="error-text">*Заполните все пустые поля</p>
          </div>
          <div class="error-messages">
            <ValidationErrors
                v-if="validationErrors"
                :validation-errors="validationErrors"
            />
          </div>

          <div class="modal-body">
            <!-- Поля формы -->
            <div class="modal-body__wrap-input">
              <label for="surname" class="label-for-input">Фамилия</label>
              <input
                  type="text"
                  name="surname"
                  v-model="surname"
                  placeholder="Введите фамилию"
                  class="input-text"
                  :class="{
                  'error-border-input': isSubmitted && vuelidateState.surname.$error,
                }"
              />
              <img
                  src="~@/assets/svg/error.svg"
                  alt="error"
                  v-if="isSubmitted && vuelidateState.surname.$error"
                  class="wrapValidationInput__error"
              />
            </div>
            <div class="modal-body__wrap-input">
              <label for="name" class="label-for-input">Имя</label>
              <input
                  type="text"
                  name="name"
                  placeholder="Введите имя"
                  v-model="name"
                  class="input-text"
                  :class="{
                  'error-border-input': isSubmitted && vuelidateState.name.$error,
                }"
              />
              <img
                  src="~@/assets/svg/error.svg"
                  alt="error"
                  v-if="isSubmitted && vuelidateState.name.$error"
                  class="wrapValidationInput__error"
              />
            </div>

            <div class="modal-body__wrap-input">
              <label for="lastName" class="label-for-input">Отчество</label>
              <input
                  type="text"
                  name="lastName"
                  v-model="lastName"
                  placeholder="Введите отчество"
                  class="input-text"
                  :class="{
                  'error-border-input': isSubmitted && vuelidateState.lastName.$error,
                }"
              />
              <img
                  src="~@/assets/svg/error.svg"
                  alt="error"
                  v-if="isSubmitted && vuelidateState.lastName.$error"
                  class="wrapValidationInput__error"
              />
            </div>
            <div class="modal-body__wrap-input">
              <label for="position" class="label-for-input">Должность</label>
              <input
                  type="text"
                  name="position"
                  v-model="position"
                  placeholder="Введите должность"
                  class="input-text"
                  :class="{
                  'error-border-input': isSubmitted && vuelidateState.position.$error,
                }"
              />
              <img
                  src="~@/assets/svg/error.svg"
                  alt="error"
                  v-if="isSubmitted && vuelidateState.position.$error"
                  class="wrapValidationInput__error"
              />
            </div>
            <!-- Поле выбора отдела -->
            <div class="modal-body__wrap-input">
              <label for="department" class="label-for-input">Назначить роль</label>
              <select
                  name="employeeRole"
                  id=""
                  class="select"
                  v-model="employeeRole"
                  :class="{
                  'error-border-input': isSubmitted && vuelidateState.employeeRole.$error,
                }"
              >
                <option value="EMPLOYEE">Врач</option>
                <option value="MANAGEMENT">Руководство</option>
                <option value="CHIEF_MEDICAL_OFFICER">Главный врач</option>
              </select>
            </div>
            <div class="modal-body__wrap-input">
              <label for="position" class="label-for-input">Часы работы (*при необходимости)</label>
              <input
                  type="text"
                  name="openingHours"
                  v-model="openingHours"
                  placeholder="Введите часы работы"
                  class="input-text"
                  :class="{
                  'error-border-input': isSubmitted && vuelidateState.openingHours.$error,
                }"
              />
              <img
                  src="~@/assets/svg/error.svg"
                  alt="error"
                  v-if="isSubmitted && vuelidateState.openingHours.$error"
                  class="wrapValidationInput__error"
              />
            </div>
            <!-- Поле выбора отдела -->
            <div class="modal-body__wrap-input">
              <label for="department" class="label-for-input">Назначить отделение</label>
              <select
                  name="department"
                  id=""
                  class="select"
                  v-model="department"
                  @change="fetchSubDepartments"
                  :class="{
                  'error-border-input': isSubmitted && vuelidateState.department.$error,
                }"
              >
                <option v-for="item in departments" :key="item.name" :value="item">{{ item.name }}</option>
              </select>
            </div>

            <!-- Поле выбора подотдела -->
            <div class="modal-body__wrap-input" v-if="underDepartments.length && department !== ''">
              <label for="subDepartment" class="label-for-input">Назначить подотделение</label>
              <select
                  name="subDepartment"
                  id=""
                  class="select"
                  v-model="subDepartment"
                  :class="{
                  'error-border-input': isSubmitted && vuelidateState.subDepartment.$error,
                }"
              >
                <option v-for="item in underDepartments" :key="item.title" :value="item">{{ item.title }}</option>
              </select>
            </div>

            <!-- Поле выбора фотографии -->
            <div class="modal-body__wrap-input-column">
              <label for="photo" class="label-for-input">Добавить фотографию</label>

              <!-- Кастомный input -->
              <div class="custom-file-input">
                <label for="file-upload" class="custom-file-label">
                  {{ photoName || "Выберите файл" }}
                </label>
                <input
                    type="file"
                    id="file-upload"
                    name="photo"
                    @change="onFileChange"
                    accept="image/*"
                    class="input-file-hidden"
                />
              </div>
            </div>

            <!-- Миниатюра выбранной фотографии -->
            <div class="modal-body__wrap-input-column" v-if="photoPreview">
              <label class="label-for-input">Предварительный просмотр</label>
              <div class="photo-preview__wrap">
                <img :src="photoPreview" alt="Preview" class="photo-preview" />
              </div>

            </div>
            <div class="" v-if="isLoading">
              <p class="color-green center">Загружаем изменения, подождите..</p>
            </div>
          </div>
          <div class="modal-footer">
            <div class="modal-footer__btn">
              <button
                  type="button"
                  class="index-btn lite-color"
                  @click="$emit('close')"
              >
                Отменить
              </button>
            </div>
            <div class="modal-footer__btn">
              <button
                  type="button"
                  class="index-btn index-color"
                  @click="onSubmitUserInfo"
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { reactive, toRefs } from 'vue';
  import useVuelidate from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  import { actionTypes } from "@/store/modules/users";
  import { mapState } from "vuex";
  import ValidationErrors from "../ValidationErrors.vue";
  import { actionTypesDepartment } from "@/store/modules/department";

  export default {
    name: "AppModalAddUser",
    data() {
      return {
        photoName: "",
        photo: null, // Данные для хранения файла
        photoPreview: null, // Миниатюра для отображения
       isSubmitted : false,
      };
    },
    setup() {
      const state = reactive({
        name: "",
        surname: "",
        lastName: "",
        position: "",
        department: "",
        subDepartment: "",
        openingHours: '',
        employeeRole: "",

      });

      const rules = {
        name: { required },
        surname: { required },
        lastName: { required },
        position: { required },
        department: { required },
        employeeRole: {required},
        subDepartment: {},
        openingHours: {},
      };

      const vuelidateState = useVuelidate(rules, state);

      return { vuelidateState, ...toRefs(state) };
    },
    components: { ValidationErrors },
    computed: {
      ...mapState({
        validationErrors: (state) => state.users.validationErrors,
        departments: (state) => state.department.departments,
        underDepartments: (state) => state.department.underDepartments,
        isLoading: (state) => state.users.isLoading,
      }),
    },
    methods: {
      onFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          this.photo = file;
          this.photoName = file.name; // Устанавливаем имя выбранного файла
          this.photoPreview = URL.createObjectURL(file); // Создание URL для миниатюры
        }
      },
      onSubmitUserInfo() {
        this.isSubmitted = true;
        this.vuelidateState.$touch();
        if (this.vuelidateState.$invalid) {
          console.log("не ок");
          return;
        } else {
          const formData = new FormData();

          const employeeData = {
            name: this.name,
            firstName: this.surname,
            lastName: this.lastName,
            medicalDepartmentId: this.department.id,
            position: this.position,
            employeeRole: this.employeeRole,
            openingHours: this.openingHours,
            medicalSubDepartmentId: this.subDepartment?.medicalSubDepartmentId || null,
          };

          formData.append("employee", JSON.stringify(employeeData));

          if (this.photo) {
            formData.append("image", this.photo);
          }

          this.$store
              .dispatch(actionTypes.addUser, formData)
              .then(() => {
                this.$emit("close");
              })
              .catch((er) => {
                console.log("Ошибка", er);
              });
        }
      },
      fetchSubDepartments() {
        if (this.department) {
          this.$store
              .dispatch(actionTypesDepartment.getUnderDepartment, this.department.id)
              .then(() => {
                this.subDepartment = null;
              })
              .catch((error) => {
                console.error("Ошибка при получении подотделений:", error);
              });
        }
      },
      getDepartment() {
        this.$store
            .dispatch(actionTypesDepartment.getDepartment)
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
    },
    mounted() {
      this.getDepartment();
    },
  };
</script>

<style>
.photo-preview {
  max-width: 150px;
  max-height: 150px;
  border-radius: 12px;
}
.photo-preview__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Скрытый стандартный input */
.input-file-hidden {
  display: none;
}

/* Стилизация кастомного label */
.custom-file-input {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-file-label {
  display: block;
  padding: 14px 18px 14px 14px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  color: #333;
  font-weight: 500;
}

.custom-file-label:hover {
  background-color: #e1e1e1;
}
</style>

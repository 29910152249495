import appealsApi from "@/api/appeals.js";

const state = {
  isLoading: false, // ожидание запроса
  validationErrors: null, // ошибки
  allAppeals: [],
  allData: [],

};

export const mutationTypes = {
  getAppealsStart: "[appeals] getAppealsStart",
  getAppealsSuccess: "[appeals] getAppealsSuccess",
  getAppealsFailure: "[appeals] getAppealsFailure",

  deleteAppealStart: "[appeals] deleteAppealStart",
  deleteAppealSuccess: "[appeals] deleteAppealSuccess",
  deleteAppealFailure: "[appeals] deleteAppealFailure",

  getStatisticsStart: "[appeals] getStatisticsStart",
  getStatisticsSuccess: "[appeals] getStatisticsSuccess",
  getStatisticsFailure: "[appeals] getStatisticsFailure",

  postStatisticsStart: "[appeals] getStatisticsStart",
  postStatisticsSuccess: "[appeals] postStatisticsSuccess",
  postStatisticsFailure: "[appeals] postStatisticsFailure",

};

export const actionTypesAppeals = {
  getAppeals: "[appeals] getAppeals",
  deleteAppeal: "[appeals] deleteAppeal",
  postStatistics: "[appeals] postStatistics",
  getStatistics: "[appeals] getStatistics",
};

const mutations = {
  // получение всех обращений
  [mutationTypes.getAppealsStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getAppealsSuccess](state, data) {
    state.isLoading = false;
    state.allAppeals = data;
  },
  [mutationTypes.getAppealsFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  // удаление
  [mutationTypes.deleteAppealStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.deleteAppealSuccess](state, data) {
    state.isLoading = false;
    state.allAppeals = data;
  },
  [mutationTypes.deleteAppealFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  // получение статистики
  [mutationTypes.getStatisticsStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getStatisticsSuccess](state, data) {
    state.isLoading = false;
    state.allData = data;
  },
  [mutationTypes.getStatisticsFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  // отправка статистики
  [mutationTypes.postStatisticsStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.postStatisticsSuccess](state, data) {
    state.isLoading = false;
    state.allData = data;
  },
  [mutationTypes.postStatisticsFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
};

const actions = {
  // получение
  [actionTypesAppeals.getAppeals](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAppealsStart);
      appealsApi
          .getAppeals()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.getAppealsSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.getAppealsFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getAppealsFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // удаление
  [actionTypesAppeals.deleteAppeal](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteAppealStart);
      appealsApi
          .deleteAppeal(id)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.deleteAppealSuccess,
                  response.data.result
              );
              resolve(response.data);
            } else {
              context.commit(mutationTypes.deleteAppealFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.deleteAppealFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // получение
  [actionTypesAppeals.getStatistics](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getStatisticsStart);
      appealsApi
          .getStatistics()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.getStatisticsSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.getStatisticsFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getStatisticsFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // получение
  [actionTypesAppeals.postStatistics](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.postStatisticsStart);
      appealsApi
          .postStatistics(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.postStatisticsSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.postStatisticsFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.postStatisticsFailure, err);
            console.log("ошибка", err);
          });
    });
  },
};

export default {
  state,
  mutations,
  actions,
};

<template>
  <div class="people__list grid-container">
    <AppPeopleItem v-for="people in people_list" :key="people.id" :people_item="people"/>
  </div>
</template>

<script>
  import AppPeopleItem from '@/components/People/PeopleItem.vue'

  export default {
    name: "AppPeopleList",
    components: { AppPeopleItem },
    props: {
      people_list: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
      };
    }
  };
</script>

<style scoped>

</style>

<template>
  <div class="users">
    <Header
        title="Новости"
        description="Добавление и изменение новостей"
    />
    <div class="wrap-loader-2" v-if="isLoading">
      <div id="loader"></div>
    </div>
    <div class="back">
      <router-link to="/news" class="back__wrap">
        <img class="back__img" src="../assets/svg/back-arrow.svg" alt="arrow">
        <p class="description__text">Вернуться к новостям</p></router-link>
    </div>
    <div class="container-page">

      <div class="users__block">

        <div class="users__wrap">
          <div class="users__add">
            <button
                type="button"
                class="index-btn index-color"
                @click="openModalDeleteNews"
            >
              <img
                  src="../assets/svg/plus.svg"
                  alt="plus"
                  class="index-btn__img"
              />
             Удалить данную новость
            </button>
          </div>
        </div>
      </div>
      <div class="users-block-people">
          <AppItemDetails :news_item="itemNews"/>
      </div>
    </div>
    <AppModalDeleteNews  v-if="isOpenModalDeleteNews"
                         :isItemNews="true"
                         @close="closeModalDeleteNews" :item_news="deleteItemNews"/>

  </div>
</template>

<script>
  import Header from "../components/Header.vue";
  import { mapState, mapActions } from "vuex";
  import { actionTypesNews } from '@/store/modules/news'
  import { actionTypes as modalActionTypes } from "@/store/modules/modal";

  import AppModalDeleteNews from '@/components/Modal/ModalDeleteNews.vue'
  import AppItemDetails from '@/components/News/NewsItemDetails.vue'


  export default {
    components: {
      AppItemDetails,

      AppModalDeleteNews,
      Header,
    },
    name: "AppItemNewsDetail",
    data() {
      return {
        searchFIO: "",
        title: "Новости",
      };
    },
    props: {

    },
    methods: {
      ...mapActions({
        setModalDeleteNewsState: modalActionTypes.setIsOpenDeleteNews,
      }),
      routePageAdd() {
        this.$router.push({name: 'AddNews'})
      },
      openModalDeleteNews() {
        this.setModalDeleteNewsState({ isOpen: true, item: this.itemNews })
      },
      closeModalDeleteNews() {
        this.setModalDeleteNewsState({ isOpen: false, item: null })
      },

    },
    computed: {
      ...mapState({
        itemNews: (state) => state.news.itemNews,
        isLoading: (state) => state.news.isLoading,
        isOpenModalDeleteNews: (state) => state.modal.isOpenDeleteNews,
        deleteItemNews: (state) => state.modal.deleteItemNews,
        token: (state) => state.auth.token,
        username: (state) => state.auth.username,
      }),

    },
    beforeMount() {
      document.title = this.title;
    },
    mounted() {
      if (!this.token) {
        this.$router.push({ name: "Login" });
      }
      this.$store.dispatch(actionTypesNews.getNewsItem, this.$route.params.id);
    },
  };
</script>

<template>
  <div class="">
    <AppAppealsItem v-for="appeal in appeals_list" :key="appeal.id" :appeal_item="appeal"/>
  </div>
</template>

<script>

  import AppAppealsItem from '@/components/Appeal/AppealItem.vue'

  export default {
    name: "AppAppealsList",
    components: { AppAppealsItem },

    props: {
      appeals_list: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
      };
    }
  };
</script>

import vacanciesApi from "@/api/vacancies.js";

const state = {
  isLoading: false, // ожидание запроса
  validationErrors: null, // ошибки
  allVacancies: [],
};

export const mutationTypes = {
  addVacancyStart: "[vacancies] addVacancyStart",
  addVacancySuccess: "[vacancies] addVacancySuccess",
  addVacancyFailure: "[vacancies] addVacancyFailure",

  getVacanciesStart: "[vacancies] getVacanciesStart",
  getVacanciesSuccess: "[vacancies] getVacanciesSuccess",
  getVacanciesFailure: "[vacancies] getVacanciesFailure",

  deleteVacancyStart: "[vacancies] deleteVacancyStart",
  deleteVacancySuccess: "[vacancies] deleteVacancySuccess",
  deleteVacancyFailure: "[vacancies] deleteVacancyFailure",

  editVacancyStart: "[vacancies] editVacancyStart",
  editVacancySuccess: "[vacancies] editVacancySuccess",
  editVacancyFailure: "[vacancies] editVacancyFailure",
};

export const actionTypesVacancies = {
  addVacancy: "[vacancies] addVacancy",
  getVacancies: "[vacancies] getVacancies",
  deleteVacancy: "[vacancies] deleteVacancy",
  editVacancy: "[vacancies] editVacancy",
};

const mutations = {
  // добавление новой вакансии
  [mutationTypes.addVacancyStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.addVacancySuccess](state, item) {
    state.isLoading = false;
    state.allVacancies.unshift(item);
  },
  [mutationTypes.addVacancyFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  // удаление
  [mutationTypes.deleteVacancyStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.deleteVacancySuccess](state, data) {
    state.isLoading = false;
    state.allVacancies = data;
  },
  [mutationTypes.deleteVacancyFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  // редактирование
  [mutationTypes.editVacancyStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.editVacancySuccess](state, item) {
    state.isLoading = false;
    const index = state.allVacancies.findIndex(vacancy => vacancy.id === item.id);
    if (index !== -1) {
      // Если вакансия с таким ID найдена, заменяем её
      state.allVacancies.splice(index, 1, item);
    }
  },
  [mutationTypes.editVacancyFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  // получение всех вакансий
  [mutationTypes.getVacanciesStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getVacanciesSuccess](state, data) {
    state.isLoading = false;
    state.allVacancies = data;
  },
  [mutationTypes.getVacanciesFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
};

const actions = {
  // добавление
  [actionTypesVacancies.addVacancy](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addVacancyStart);
      vacanciesApi
          .addVacancy(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.addVacancySuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.addVacancyFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.addVacancyFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // получение
  [actionTypesVacancies.getVacancies](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getVacanciesStart);
      vacanciesApi
          .getVacancies()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.getVacanciesSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.getVacanciesFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getVacanciesFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // удаление
  [actionTypesVacancies.deleteVacancy](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteVacancyStart);
      vacanciesApi
          .deleteVacancy(id)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.deleteVacancySuccess,
                  response.data.result
              );
              resolve(response.data);
            } else {
              context.commit(mutationTypes.deleteVacancyFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.deleteVacancyFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  // редактирование
  [actionTypesVacancies.editVacancy](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editVacancyStart);
      vacanciesApi
          .editVacancy(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.editVacancySuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.editVacancyFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.editVacancyFailure, err);
            console.log("ошибка", err);
          });
    });
  },
};

export default {
  state,
  mutations,
  actions,
};

<template>
  <div class="users">
    <Header
        title="Специалисты"
        description="Добавление и изменение специалистов"
    />
    <div class="wrap-loader-2" v-if="isLoading">
      <div id="loader"></div>
    </div>
    <div class="container-page">
      <div class="users__block">
        <div class="users__search" :class="{ disable: !isActiveDoctors }">
          <input
              type="text"
              placeholder="Поиск по фамилии и имени"
              class="testing__input"
              :class="{ disable: !isActiveDoctors }"
              :disabled="!isActiveDoctors"
              v-model="searchFIO"
          />
          <img
              src="../assets/svg/search.svg"
              alt="search"
              :class="{ 'none': !isActiveDoctors }"
              @click="searchFIOClick"
              class="testing__input-svg"
          />
        </div>
        <div class="users__wrap">
          <div class="users__add">
            <button
                type="button"
                class="index-btn index-color"
                @click="openModalAddUser"
            >
              <img
                  src="../assets/svg/plus.svg"
                  alt="plus"
                  class="index-btn__img"
              />
              Добавить специалиста
            </button>
          </div>
        </div>
      </div>
      <div class="data-tab">
        <div class="data-tab__wrap">
          <div
              class="data-tab__btn"
              @click="TabActiveBlock('doctors')"
              :class="{ 'active-btn': this.isActiveDoctors }"
          >
            <p
                class="data-tab__text"
                :class="{ 'active-text': this.isActiveDoctors }"
            >
              Врачи

            </p>
          </div>
          <div
              class="data-tab__btn"
              @click="TabActiveBlock('managements')"
              :class="{ 'active-btn': this.isActiveManagements }"
          >
            <p
                class="data-tab__text"
                :class="{ 'active-text': this.isActiveManagements }"
            >
              Руководство
            </p>
          </div>
          <div
              class="data-tab__btn"
              @click="TabActiveBlock('main')"
              :class="{ 'active-btn': this.isActiveMain }"
          >
            <p
                class="data-tab__text"
                :class="{ 'active-text': this.isActiveMain }"
            >
              Глав. врач
            </p>
          </div>
        </div>
      </div>
      <div class="users-block-people">
        <AppPeopleList :people_list="peopleList"/>
      </div>
    </div>

    <ModalAddUser
        v-if="isOpenModalAddUser"
        @close="closeModalAddUser"
    />
    <ModalEditUser
        v-if="isOpenModalEditUser"
        @close="closeModalEditUser"
        :item_user="editItem"
    />
    <AppModalDeleteUser
        v-if="isOpenModalDeleteUser"
        @close="closeModalDeleteUser"
        :item_user="deleteItem"
    />
  </div>
</template>

<script>
  import Header from "../components/Header.vue";
  import ModalAddUser from "../components/Modal/ModalAddUser.vue";
  import ModalEditUser from "../components/Modal/ModalEditUser.vue";
  import AppPeopleList from '@/components/People/PeopleList.vue';
  import { actionTypes } from "@/store/modules/users";
  import { mapState, mapActions } from "vuex";
  import { actionTypes as modalActionTypes } from "@/store/modules/modal";
  import AppModalDeleteUser from '@/components/Modal/ModalDeleteUser.vue'

  export default {
    components: {
      AppModalDeleteUser,
      AppPeopleList,
      Header,
      ModalAddUser,
      ModalEditUser,
    },
    name: "AppSpecialists",
    data() {
      return {
        searchFIO: "",
        title: "Специалисты",
        isActiveMain: false,
        isActiveManagements: false,
        isActiveDoctors: true
      };
    },
    props: {
      size: {
        type: Number,
        required: false,
        default: 7,
      },
      maxVisibleButtons: {
        type: Number,
        required: false,
        default: 5,
      },
    },
    methods: {
      ...mapActions({
        openModalAddUserAction: modalActionTypes.setIsOpenAddPeople,
        setModalEditUserState: modalActionTypes.setIsOpenEditPeople,
        setModalDeleteUserState: modalActionTypes.setIsOpenDeletePeople,
      }),
      TabActiveBlock(tab) {
        if (tab === "main") {
          this.isActiveMain = true;
          this.isActiveManagements = false;
          this.isActiveDoctors = false;
        } else if (tab === "managements") {
          this.isActiveMain = false;
          this.isActiveManagements = true;
          this.isActiveDoctors = false;
        } else if (tab === "doctors") {
          this.isActiveMain = false;
          this.isActiveManagements = false;
          this.isActiveDoctors = true;
        }
      },
      openModalAddUser() {
        this.openModalAddUserAction(true);
      },
      closeModalAddUser() {
        this.openModalAddUserAction(false);
      },
      openModalEditUser(item) {
        this.setModalEditUserState({ isOpen: true, item });
      },
      closeModalEditUser() {
        this.setModalEditUserState({ isOpen: false, item: null });
      },
      openModalDeleteUser(item) {
        this.setModalDeleteUserState({ isOpen: true, item: item })
      },
      closeModalDeleteUser() {
          this.setModalDeleteUserState({ isOpen: false, item: null })
      },
      performFioSearch(fio) {
        if (fio !== "") {
          this.$store.dispatch(actionTypes.getSearchFio, fio);
        } else {
          this.$store.dispatch(actionTypes.getUsers);
        }
      },
      searchFIOClick() {
        this.performFioSearch(this.searchFIO);
      },
    },
    computed: {
      ...mapState({
        allUsers: (state) => state.users.allUsers,
        EMPLOYEES: (state) => state.users.EMPLOYEES,
        MANAGEMENTS: (state) => state.users.MANAGEMENTS,
        CHIEF_MEDICAL_OFFICER: (state) => state.users.CHIEF_MEDICAL_OFFICER,
        isLoading: (state) => state.users.isLoading,
        isOpenModalAddUser: (state) => state.modal.isOpenAddPeople,
        isOpenModalEditUser: (state) => state.modal.isOpenEditPeople,
        isOpenModalDeleteUser: (state) => state.modal.isOpenDeletePeople,
        editItem: (state) => state.modal.editItem,
        deleteItem: (state) => state.modal.deleteItem,
        token: (state) => state.auth.token,
        username: (state) => state.auth.username,
      }),
      peopleList() {
        if (this.isActiveMain) {
          return this.CHIEF_MEDICAL_OFFICER;
        } else if (this.isActiveManagements) {
          return this.MANAGEMENTS;
        } else if (this.isActiveDoctors) {
          return this.EMPLOYEES;
        } else {
          return this.allUsers;
        }
      },
      totalPages() {
        return this.allUsers ? Math.ceil(this.allUsers.length / this.size) : 0;
      },
    },
    watch: {
      searchFIO(val) {
        this.performFioSearch(val);
      },
    },
    beforeMount() {
      document.title = this.title;
    },
    mounted() {
      if (!this.token) {
        this.$router.push({ name: "Login" });
      }
      this.$store.dispatch(actionTypes.getUsers);
    },
  };
</script>

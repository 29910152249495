<template>
<!--  @click="$emit('close')"-->
  <transition name="modal" >
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container mini-width">
          <div class="modal-header">
            <p class="title-modal">Удаление пользователя</p>
            <div class="modal-header__cross" @click="$emit('close')">
              <img src="../../assets/svg/CrossModal.svg" alt="cross" />
            </div>
          </div>
          <div class="modal-body-mini">
            <p class="label-for-input">
              Вы действительно хотите удалить данного пользователя?
            </p>
          </div>
          <div class="modal-footer">
            <div class="modal-footer__btn">
              <button
                type="button"
                class="index-btn lite-color"
                @click="$emit('close')"
              >
                Нет
              </button>
            </div>
            <div class="modal-footer__btn">
              <button
                type="button"
                class="index-btn index-color"
                @click="onDeleteUserInfo"
              >
                Да
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { actionTypes } from "@/store/modules/users";

export default {
  name: "AppModalDeleteUser",
  data() {
    return {};
  },
  props: {
    item_user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    onDeleteUserInfo() {
      this.$store
        .dispatch(actionTypes.deleteUser, this.item_user.id)
        .then(() => {
          this.$emit("close");
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },
};
</script>

<style></style>

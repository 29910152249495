<template>
  <div class="" style="width: 100%">
    <div class="">
      <div class="news-block">
        <div class="news-block__info">
          <div class="news-block__date">
            <p class="news-title color-gray">{{news_item.dateTime}}</p>
          </div>
          <div class="news-block__title">
            <p class="news-title">{{news_item.title}}</p>
          </div>
          <div class="news-block__description">
            <p class="text-circe-light-18 left" v-html="content"></p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex'
  import { actionTypes as modalActionTypes } from '@/store/modules/modal'

  export default {
    name: "AppItemDetails",
    props: {
      news_item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {

      };
    },
    computed: {
      content() {
        return this.news_item.content;
      },
    },
    methods: {
      ...mapActions({
        // setModalEditVacancyState: modalActionTypes.setIsOpenEditVacancy,
        setModalDeleteNewsState: modalActionTypes.setIsOpenDeleteNews,
      }),
      openModalDeleteNews(item) {
        this.setModalDeleteNewsState({ isOpen: true, item: item })
      },
      moreDetails() {
        this.$router.push({ name: 'ItemNewsDetail', params: { id: this.news_item.id } });
      }
      // openModalEdit(item) {
      //   this.setModalEditVacancyState({ isOpen: true, item });
      // },
    }
  };
</script>

<template>
  <div class="news-editor">
    <Header
        title="Добавить новость"
        description="Добавление новостей"
    />
    <div class="wrap-loader-2" v-if="isLoading">
      <div id="loader"></div>
    </div>
    <div class="back">
      <router-link to="/news" class="back__wrap">
        <img class="back__img" src="../assets/svg/back-arrow.svg" alt="arrow" />
        <p class="description__text">Вернуться к новостям</p>
      </router-link>
    </div>
    <div class="container-page">
      <div class="news-editor__content">
        <form @submit.prevent="onSubmit">
          <div class="form-group">
            <label for="title" class="news__title">Название новости</label>
            <input type="text" v-model="newsTitle" class="input-text" required />
          </div>
          <div class="form-group">
            <label for="description" class="news__title">Краткое описание (Превью)</label>
            <input type="text" v-model="newsDescription" class="input-text" required />
          </div>
          <!-- Поле выбора фотографии -->
          <div class="modal-body__wrap-input-column">
            <label for="photo" class="news__title">Прикрепите фотографию для миниатюры</label>
            <div class="custom-file-input">
              <label for="file-upload" class="custom-file-label">
                {{ photoName || "Выберите файл" }}
              </label>
              <input
                  type="file"
                  required
                  id="file-upload"
                  name="photo"
                  @change="onFileChange"
                  accept="image/*"
                  class="input-file-hidden"
              />
            </div>
          </div>

          <!-- Миниатюра выбранной фотографии -->
          <div class="modal-body__wrap-input-column" v-if="photoPreview">
            <label class="news__title">Предварительный просмотр</label>
            <div class="photo-preview__wrap">
              <img :src="photoPreview" alt="Preview" class="photo-preview-big" />
            </div>
          </div>
          <div class="form-group">
            <label for="content" class="news__title">Новость</label>
            <froala
                ref="froalaEditor"
                :tag="'textarea'"
                :config="editorConfig"
                v-model:value="model"
            ></froala>
          </div>
          <div class="news__footer">
            <button type="submit" class="index-btn index-color">Сохранить новость</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from '../components/Header.vue';
  import { mapState } from 'vuex';
  import { actionTypesNews } from '@/store/modules/news'
  import axios from 'axios';

  export default {
    name: 'AppAddNewsFlo',
    components: {
      Header
    },
    data() {
      return {
        newsTitle: '',
        newsDescription: '',
        model: '',
        photoName: '',
        photo: null,
        photoPreview: null,
        editorConfig: {
          imageAllowedTypes: ['jpeg', 'jpg', 'png'],
          placeholderText: 'Впишите свою новость',
          events: {
            'froalaEditor.initialized': (e, editor) => {
              console.log('Froala Editor initialized event triggered');
            },
            'image.beforeUpload': (images) => {
              console.log('Image beforeUpload event triggered');

              const formData = new FormData();
              formData.append('file', images[0]);

              axios.post('/news/save-image', formData, {
                headers: {
                  'Authorization': `${this.token}`, // Используйте ваш токен
                  'Content-Type': 'multipart/form-data'
                }
              })
                  .then(response => {
                    const imageUrl = response.data.result;

                    const editorInstance = this.$refs.froalaEditor.getEditor(); // Получаем экземпляр редактора
                    editorInstance.image.insert(imageUrl, null, null, editorInstance.image.get());

                    console.log('Image inserted');
                  })
                  .catch(error => {
                    console.error('Image upload error:', error);
                  });

              return false;
            },
          }
        }
      };
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.news.isLoading,
        token: (state) => state.auth.token,
      }),
    },
    methods: {
      onFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          this.photo = file;
          this.photoName = file.name;
          this.photoPreview = URL.createObjectURL(file);
        }
      },
      onSubmit() {
        const formData = new FormData();

        const newsData = {
          title: this.newsTitle,
          content: this.model,
          description: this.newsDescription,
        };

        formData.append('news', JSON.stringify(newsData));

        if (this.photo) {
          formData.append('image', this.photo);
        }

        this.$store.dispatch(actionTypesNews.addNews, formData)
            .then(() => {
              this.newsTitle = '';
              this.model = '';
              this.newsDescription = '';
              this.photo = null;
              this.photoName = null;
              this.photoPreview = null;
            })
            .then(() => {
              this.$router.push({ name: "News" });
            })
            .catch((er) => {
              console.log('Ошибка', er);
            });
      }
    },
    mounted() {
      if (!this.token) {
        this.$router.push({ name: 'Login' });
      }

      this.$nextTick(() => {
        if (this.$refs.froalaEditor && this.$refs.froalaEditor.$el) {
          console.log('Froala Editor initialized in mounted');
        } else {
          console.error('Froala Editor not initialized');
        }
      });
    }
  };
</script>

<style scoped>
/* Ваши стили для компонента */
</style>

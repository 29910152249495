<template>
  <div class="header-container">
    <div class="header">
      <div class="wrap-title">
        <div class="title">
          <!-- <arrow-left fill="black" /> -->
          <p class="title__text">
            {{ title }} <span class="number" v-if="imei">{{ imei }}</span>
          </p>
        </div>
        <div class="description">
          <p class="description__text">{{ description }}</p>
        </div>
      </div>
      <div class="wrap-profile">
          <div tooltip="Имя пользователя" class="profile-mini__icon">
            <div class="icon">
              <img src="../assets/svg/user-mini.svg" alt="user" />
            </div>
            <div class="profile-mini__text">
              <p class="text-profile">{{ username }}</p>
            </div>
<!--            <div class="profile-mini__arrow">-->
<!--              <img src="../assets/svg/arrow-bottom.svg" alt="arrow" />-->
<!--            </div>-->
          </div>

        <div class="profile-mini__exit" @click="exit" tooltip="Выход">
          <img src="../assets/svg/exit.svg" alt="exit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/auth";
import { mapState } from "vuex";
// import ArrowLeft from "./Svg/Arrow-Left.vue";
export default {
  // components: { ArrowLeft },
  name: "AppHeader",
  props: {
    title: {
      type: String,
      default() {
        return {};
      },
    },
    description: {
      type: String,
      default() {
        return {};
      },
    },
    imei: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapState({
      username: (state) => state.auth.username,
    }),
  },
  methods: {
    exit() {
      this.$store
        .dispatch(actionTypes.exitLogin)
        .then(() => {
          this.$router.push({ name: "Login" });
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
      // exitLogin
    },
  },
};
</script>

<style></style>

import DepartmentAPi from "@/api/department.js";
const state = {
  isLoading: false, //ожидание запроса
  validationErrors: null, //ошибки
  departments: [],
  underDepartments: [],
};

export const mutationTypes = {
  getDepartmentStart: "[department] getDepartmentStart",
  getDepartmentSuccess: "[department] getDepartmentSuccess",
  getDepartmentFailure: "[department] getDepartmentFailure",


  getUnderDepartmentStart: "[department] getUnderDepartmentStart",
  getUnderDepartmentSuccess: "[department] getUnderDepartmentSuccess",
  getUnderDepartmentFailure: "[department] getUnderDepartmentFailure",


};
export const actionTypesDepartment = {
  getUnderDepartment: "[department] getInfoCompetitions",
  getDepartment: "[department] getDepartment",

};

const mutations = {
  //Получение отделений
  [mutationTypes.getDepartmentStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getDepartmentSuccess](state, info) {
    state.isLoading = false;
    state.departments = info
  },

  [mutationTypes.getDepartmentFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },


  //Получение подотделений по отделению
  [mutationTypes.getUnderDepartmentStart](state) {
    state.isLoading = true;
    state.underDepartments = []
  },
  [mutationTypes.getUnderDepartmentSuccess](state, info) {
    state.isLoading = false;
    state.underDepartments = info
  },
  [mutationTypes.getUnderDepartmentFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },

};
const actions = {
  [actionTypesDepartment.getDepartment](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getDepartmentStart);
      DepartmentAPi.getDepartment()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getDepartmentSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getDepartmentFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getDepartmentFailure, response);
            console.log("ошибка", response);
          });
    });
  },

  [actionTypesDepartment.getUnderDepartment](context,id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getUnderDepartmentStart);
      DepartmentAPi.getUnderDepartment(id)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getUnderDepartmentSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getUnderDepartmentFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getUnderDepartmentFailure, response);
            console.log("ошибка", response);
          });
    });
  },


};

export default {
  state,
  mutations,
  actions,
};

<template>
  <div class="">
    <AppVacancyItem v-for="vacancy in vacancy_list" :key="vacancy.id" :vacancy_item="vacancy"/>
  </div>
</template>

<script>

  import AppVacancyItem from '@/components/Vacancy/VacancyItem.vue'

  export default {
    name: "AppVacancyList",
    components: { AppVacancyItem },

    props: {
      vacancy_list: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
      };
    }
  };
</script>

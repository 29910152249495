<template>
  <div class="users">
    <Header
        title="Обращения"
        description="Просмотр и удаление обращений"
    />
    <div class="wrap-loader-2" v-if="isLoading">
      <div id="loader"></div>
    </div>
    <div class="container-page">
      <div class="users-block-people">
          <AppAppealsList :appeals_list="allAppeals"/>
      </div>
    </div>
    <AppModalDeleteAppeals v-if="isOpenModalDeleteAppeals"
                       @close="closeModalDeleteAppeals" :item_appeals="deleteItemAppeals"/>

  </div>
</template>

<script>
  import Header from "../components/Header.vue";
  import { mapState, mapActions } from "vuex";
  import { actionTypes as modalActionTypes } from "@/store/modules/modal";
  import AppModalDeleteAppeals from '@/components/Modal/ModalDeleteAppeals.vue'
  import { actionTypesAppeals } from '@/store/modules/appeals'
  import AppAppealsList from '@/components/Appeal/AppealList.vue'

  export default {
    components: {
      AppAppealsList,
      AppModalDeleteAppeals,
      Header,
    },
    name: "AppAppeals",
    data() {
      return {
        searchFIO: "",
        title: "Обращение",
      };
    },
    props: {

    },
    methods: {
      ...mapActions({
        setModalDeleteAppealsState: modalActionTypes.setIsOpenDeleteAppeals,
      }),
      openModalDeleteNews(item) {
        this.setModalDeleteAppealsState({ isOpen: true, item: item })
      },
      closeModalDeleteAppeals() {
        this.setModalDeleteAppealsState({ isOpen: false, item: null })
      },

    },
    computed: {
      ...mapState({
        allAppeals: (state) => state.appeals.allAppeals,
        isLoading: (state) => state.appeals.isLoading,
        isOpenModalDeleteAppeals: (state) => state.modal.isOpenDeleteAppeals,
        deleteItemAppeals: (state) => state.modal.deleteItemAppeals,
        token: (state) => state.auth.token,
        username: (state) => state.auth.username,
      }),

    },
    beforeMount() {
      document.title = this.title;
    },
    mounted() {
      if (!this.token) {
        this.$router.push({ name: "Login" });
      }
      this.$store.dispatch(actionTypesAppeals.getAppeals);
    },
  };
</script>

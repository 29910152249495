<template>
  <div class="" style="width: 100%">
    <div class="">
      <div class="news__item news-block">
        <div class="news-block__img">
          <img :src="news_item.imageUrl" :alt="news_item.title">
        </div>
        <div class="news-block__info">
          <div class="news-block__date">
            <p class="news-title">{{news_item.date}}</p>
          </div>
          <div class="news-block__title">
            <p class="news-title">{{news_item.title}}</p>
          </div>
          <div class="news-block__description">
            <p class="text-circe-14">{{ news_item.description }}</p>
          </div>
          <div class="news-block__btn-wrap">
            <div class="people-block__btn">
              <button
                  type="button"
                  class="index-btn index-color"
                  @click="moreDetails"
              >
                Подробнее
              </button>
              <button
                  type="button"
                  class="index-btn index-color"
                  @click="openModalDeleteNews(news_item)"
              >
                Удалить
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex'
  import { actionTypes as modalActionTypes } from '@/store/modules/modal'

  export default {
    name: "AppNewsItem",
    props: {
      news_item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {

      };
    },
    methods: {
      ...mapActions({
        // setModalEditVacancyState: modalActionTypes.setIsOpenEditVacancy,
        setModalDeleteNewsState: modalActionTypes.setIsOpenDeleteNews,
      }),
      openModalDeleteNews(item) {
        this.setModalDeleteNewsState({ isOpen: true, item: item })
      },
      moreDetails() {
        this.$router.push({ name: 'ItemNewsDetail', params: { id: this.news_item.id } });
      }
      // openModalEdit(item) {
      //   this.setModalEditVacancyState({ isOpen: true, item });
      // },
    }
  };
</script>

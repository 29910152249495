<template>
  <aside :class="`${this.is_expanded ? 'is-expanded' : ''}`">
    <div class="logo">
      <img src="../assets/svg/logo.svg" alt="logo" />
    </div>
    <!--
    <div class="menu-toggle-wrap">
      <button class="menu-toggle" @click="ToggleMenu">
        <span class="material-icons">ТОЧКА</span>
      </button>
    </div> -->
    <div class="menu">
      <router-link to="/" class="button-new">
        <div class="menu-wrap-img">
          <img src="../assets/svg/users.svg" alt="users" class="menu-img" />
        </div>
        <span class="text">Специалисты</span>
      </router-link>
      <router-link to="/news" class="button-new">
        <div class="menu-wrap-img">
          <img src="../assets/svg/testing.svg" alt="testing" class="menu-img" />
        </div>
        <span class="text">Новости</span>
      </router-link>
      <router-link to="/reviews" class="button-new">
        <div class="menu-wrap-img">
          <img
              src="../assets/svg/proshivca.svg"
              alt="proshivca"
              class="menu-img"
          />
        </div>
        <span class="text">Отзывы</span>
      </router-link>
      <router-link to="/vacancies" class="button-new">
        <div class="menu-wrap-img">
          <img
              src="../assets/svg/phone.svg"
              alt="proshivca"
              class="menu-img-size"
          />
        </div>
        <span class="text">Вакансии</span>
      </router-link>
      <router-link to="/appeals" class="button-new">
        <div class="menu-wrap-img">
          <img
              src="../assets/svg/candidate.svg"
              alt="appeal"
              class="menu-img-size"
          />
        </div>
        <span class="text">Обращения</span>
      </router-link>
      <router-link to="/statistics" class="button-new">
        <div class="menu-wrap-img">
          <img
              src="../assets/svg/statistic.svg"
              alt="appeal"
              class="menu-img-size"
          />
        </div>
        <span class="text">Статистика</span>
      </router-link>
    </div>

    <div class="flex"></div>

    <!-- <div class="menu">
      <router-link to="/settings" class="button">

        <span class="text">Settings</span>
      </router-link>
    </div> -->
  </aside>
</template>

<script>
  // import { ref } from "vue";
  export default {
    name: "AppSidebar",
    data() {
      return {
        is_expanded: true,
        // is_expanded: ref(localStorage.getItem("is_expanded") === "false"),
      };
    },
    methods: {
      ToggleMenu() {
        this.is_expanded = !this.is_expanded;
        localStorage.setItem("is_expanded", this.is_expanded);
      },
    },
  };
</script>

<style lang="scss" scoped></style>

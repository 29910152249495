import usersApi from "@/api/users.js";

const state = {
  isLoading: false, //ожидание запроса
  validationErrors: null, //ошибки
  allUsers: [],
  EMPLOYEES: [],
  MANAGEMENTS: [],
  CHIEF_MEDICAL_OFFICER: [],
};

export const mutationTypes = {
  addUserStart: "[users] addUserStart",
  addUserSuccess: "[users] addUserSuccess",
  addUserFailure: "[users] addUserFailure",

  getUsersStart: "[users] getUsersStart",
  getUsersSuccess: "[users] getUsersSuccess",
  getUsersFailure: "[users] getUsersFailure",

  deleteUserStart: "[users] deleteUserStart",
  deleteUserSuccess: "[users] deleteUserSuccess",
  deleteUserFailure: "[users] deleteUserFailure",

  editUserStart: "[users] editUserStart",
  editUserSuccess: "[users] editUserSuccess",
  editUserFailure: "[users]  editUserFailure",

  getSearchFioStart: "[users] getSearchFioStart",
  getSearchFioSuccess: "[users] getSearchFioSuccess",
  getSearchFioFailure: "[users]  getSearchFioFailure",
};
export const actionTypes = {
  addUser: "[users] addUser",
  getUsers: "[users] getUsers",
  deleteUser: "[users] deleteUser",
  editUser: "[users]  editUser",
  getSearchFio: "[users] getSearchFio",
};

const mutations = {
  //добавление нового пользователя
  [mutationTypes.addUserStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.addUserSuccess](state, item) {
    state.isLoading = false;
    // console.log(item);

    // Определяем массив на основе роли сотрудника и инициализируем его, если он не определен
    let targetArray;

    switch(item.employeeRole) {
      case 'CHIEF_MEDICAL_OFFICER':
        if (!state.CHIEF_MEDICAL_OFFICER) {
          state.CHIEF_MEDICAL_OFFICER = [];
        }
        targetArray = state.CHIEF_MEDICAL_OFFICER;
        break;
      case 'EMPLOYEE':
        if (!state.EMPLOYEES) {
          state.EMPLOYEES = [];
        }
        targetArray = state.EMPLOYEES;
        break;
      case 'MANAGEMENT':
        if (!state.MANAGEMENTS) {
          state.MANAGEMENTS = [];
        }
        targetArray = state.MANAGEMENTS;
        break;
      default:
        return; // В случае неизвестной роли ничего не делаем
    }

    // Добавляем элемент в начало массива
    targetArray.unshift(item);
  },

  [mutationTypes.addUserFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  //удаление
  [mutationTypes.deleteUserStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.deleteUserSuccess](state, data) {
    state.isLoading = false;
    state.allUsers = data;
    state.EMPLOYEES = data.EMPLOYEES;
    state.MANAGEMENTS = data.MANAGEMENTS;
    state.CHIEF_MEDICAL_OFFICER = data.CHIEF_MEDICAL_OFFICER;

  },
  [mutationTypes.deleteUserFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  //редактирование
  [mutationTypes.editUserStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.editUserSuccess](state, item) {
    state.isLoading = false;

    // Определяем массив на основе роли сотрудника
    let targetArray;

    switch(item.employeeRole) {
      case 'CHIEF_MEDICAL_OFFICER':
        targetArray = state.CHIEF_MEDICAL_OFFICER;
        break;
      case 'EMPLOYEE':
        targetArray = state.EMPLOYEES;
        break;
      case 'MANAGEMENT':
        targetArray = state.MANAGEMENTS;
        break;
      default:
        return; // В случае неизвестной роли ничего не делаем
    }

    // Обновление или добавление пользователя в соответствующий массив на основе роли
    const roleIndex = targetArray.findIndex(user => user.id === item.id);
    if (roleIndex !== -1) {
      // Если пользователь найден, заменяем его новым объектом
      targetArray.splice(roleIndex, 1, item);
    } else {
      // Если пользователь не найден, инициируем запрос на обновление всех пользователей
      // Это нужно сделать в экшене, так как мутации должны быть синхронными
      // return false; // Возвращаем значение, чтобы указать, что пользователь не найден
    }
  },



  [mutationTypes.editUserFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  //получение всех пользователей
  [mutationTypes.getUsersStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getUsersSuccess](state, info) {
    state.isLoading = false;
    state.allUsers = info;
    state.EMPLOYEES = info.EMPLOYEES;
    state.MANAGEMENTS = info.MANAGEMENTS;
    state.CHIEF_MEDICAL_OFFICER = info.CHIEF_MEDICAL_OFFICER;
  },
  [mutationTypes.getUsersFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },

  //поиск по фио  getSearchFio
  [mutationTypes.getSearchFioStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getSearchFioSuccess](state, data) {
    state.isLoading = false;
    console.log(data,'data')
    state.EMPLOYEES = data.EMPLOYEES;
  },
  [mutationTypes.getSearchFioFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
};
const actions = {
  //добавление
  [actionTypes.addUser](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addUserStart);
      usersApi
        .addUser(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.addUserSuccess, response.data.result);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.addUserFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.addUserFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //получение
  [actionTypes.getUsers](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getUsersStart);
      usersApi
        .getUsers()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.getUsersSuccess, response.data.result);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.getUsersFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getUsersFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //удаление
  [actionTypes.deleteUser](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteUserStart);
      usersApi
        .deleteUser(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.deleteUserSuccess,
              response.data.result
            );
            resolve(response.data);
          } else {
            context.commit(mutationTypes.deleteUserFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.deleteUserFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // редактирование
  [actionTypes.editUser](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editUserStart);
      usersApi
          .editUser(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              const updatedUser = response.data.result;

              // Определяем массив на основе роли
              let targetArray;

              switch(updatedUser.employeeRole) {
                case 'CHIEF_MEDICAL_OFFICER':
                  targetArray = context.state.CHIEF_MEDICAL_OFFICER;
                  break;
                case 'EMPLOYEE':
                  targetArray = context.state.EMPLOYEES;
                  break;
                case 'MANAGEMENT':
                  targetArray = context.state.MANAGEMENTS;
                  break;
                default:
                  targetArray = null;
              }

              // Проверяем, существует ли пользователь в новом массиве
              const userExists = targetArray ? targetArray.some(user => user.id === updatedUser.id) : false;

              // Если пользователь не найден, вызываем экшен getUsers
              if (!userExists) {
                context.dispatch(actionTypes.getUsers).then(() => {
                  resolve(response.data);
                });
              } else {
                // Если пользователь найден, просто коммитим мутацию
                context.commit(mutationTypes.editUserSuccess, updatedUser);
                resolve(response.data);
              }
            } else {
              context.commit(mutationTypes.editUserFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.editUserFailure, err);
            console.log("ошибка", err);
          });
    });
  },

  // [actionTypes.editUser](context, data) {
  //   return new Promise((resolve) => {
  //     context.commit(mutationTypes.editUserStart);
  //     usersApi
  //         .editUser(data)
  //         .then((response) => {
  //           if (response.data.status === "SUCCESS") {
  //             // Вызываем мутацию и проверяем, был ли пользователь найден и обновлен
  //             const userUpdated = context.commit(mutationTypes.editUserSuccess, response.data.result);
  //             console.log(userUpdated,'userUpdated')
  //             // Если пользователь не был найден, вызываем экшен getUsers
  //             if (userUpdated === false) {
  //               context.dispatch(actionTypes.getUsers).then(() => {
  //                 resolve(response.data);
  //               });
  //             } else {
  //               resolve(response.data);
  //             }
  //           } else {
  //             context.commit(mutationTypes.editUserFailure, response.data);
  //           }
  //         })
  //         .catch((err) => {
  //           context.commit(mutationTypes.editUserFailure, err);
  //           console.log("ошибка", err);
  //         });
  //   });
  // },

  // [actionTypes.editUser](context, data) {
  //   return new Promise((resolve) => {
  //     context.commit(mutationTypes.editUserStart);
  //     usersApi
  //       .editUser(data)
  //       .then((response) => {
  //         if (response.data.status === "SUCCESS") {
  //           context.commit(mutationTypes.editUserSuccess, response.data.result);
  //           resolve(response.data);
  //         } else {
  //           context.commit(mutationTypes.editUserFailure, response.data);
  //         }
  //       })
  //       .catch((err) => {
  //         context.commit(mutationTypes.editUserFailure, err);
  //         console.log("ошибка", err);
  //       });
  //   });
  // },
  // Поиск по ФИО
  [actionTypes.getSearchFio](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getSearchFioStart);
      usersApi
        .getSearchFio(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getSearchFioSuccess,
              response.data.result
            );
            resolve(response.data);
          } else {
            context.commit(mutationTypes.getSearchFioFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getSearchFioFailure, err);
          console.log("ошибка", err);
        });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};

import axios from "@/api/axios";

const addVacancy = (data) => {
  return axios.post(`/vacancies/save`, data);
};

const getVacancies = () => {
  return axios.get(`/vacancies`);
};

const deleteVacancy = (vacancyId) => {
  return axios.post(`/vacancies/delete?id=${vacancyId}`);
};

const editVacancy = (data) => {
  return axios.post(`/vacancies/edit`, data);
};

export default {
  addVacancy,
  getVacancies,
  deleteVacancy,
  editVacancy,
};

<template>
  <div class="users">
    <Header
        title="Статистика"
        description="Добавление данных на страницу"
    />
    <div class="wrap-loader-2" v-if="isLoading">
      <div id="loader"></div>
    </div>
    <div class="container-page">
      <div class="statistics-data">
        <div class="statistics-block">
          <h3>Родов с момента открытия: {{ allData.countOpen }}</h3>
        </div>
        <div class="statistics-block">
          <h3>Родов с начала года: {{ allData.countStartYear }}</h3>
        </div>
        <div class="statistics-block">
          <h3>Родов за день: {{ allData.countDay }}</h3>
        </div>
      </div>

      <div class="form-data">
        <div class="form-data__title">
        <h4 class="title__text">Изменить статистические данные</h4>
        </div>
        <form @submit.prevent="submitForm">
          <div class="ford-data__row">
          <div class="form-group">
            <label for="countOpen" class="label-for-input">Родов с момента открытия</label>
            <input
                id="countOpen"
                name="countOpen"
                type="number"
                v-model="countOpen"
                placeholder="Введите число"

                class="input-text margin-top10"
            />
          </div>
          <div class="form-group">
            <label for="countStartYear" class="label-for-input">Родов с начала года</label>
            <input
                id="countStartYear"
                name="countStartYear"
                type="number"
                v-model="countStartYear"
                placeholder="Введите число"
                class="input-text margin-top10"
            />
          </div>
          <div class="form-group">
            <label for="countDay" class="label-for-input ">Родов за день</label>
            <input
                id="countDay"
                name="countDay"
                type="number"
                v-model="countDay"
                placeholder="Введите число"
                class="input-text margin-top10"
            />
          </div>
          </div>
          <button type="submit" class="btn-submit" :disabled="!isFormValid">Отправить</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from "../components/Header.vue";
  import { mapState, mapActions } from "vuex";
  import { actionTypesAppeals } from '@/store/modules/appeals'
  import { mutationTypes } from '@/store/modules/department'

  export default {
    components: {
      Header,
    },
    name: "AppStatistics",
    data() {
      return {
        searchFIO: "",
        title: "Статистика",
        countOpen: '',
        countStartYear: '',
        countDay: '',
      };
    },
    computed: {
      ...mapState({
        allData: (state) => state.appeals.allData,
        isLoading: (state) => state.appeals.isLoading,
        token: (state) => state.auth.token,
      }),
      isFormValid() {
        // Проверяем, чтобы все поля были заполнены
        return this.countOpen !== '' && this.countStartYear !== '' && this.countDay !== '';
      }
    },
    methods: {
      submitForm() {
        const data = {
          countOpen: this.countOpen,
          countStartYear: this.countStartYear,
          countDay: this.countDay,
        };
        this.$store.dispatch(actionTypesAppeals.postStatistics, data)
            .then(() => {
              this.countOpen =  '';
              this.countStartYear =  '';
              this.countDay =  '';
            })
            .catch((response) => {
              console.log("ошибка", response);
            });
      },
    },
    beforeMount() {
      document.title = this.title;
    },
    mounted() {
      if (!this.token) {
        this.$router.push({ name: "Login" });
      }
      this.$store.dispatch(actionTypesAppeals.getStatistics);
    },
  };
</script>

<style scoped>
.statistics-data {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.statistics-block {
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 8px;
}

.form-data {
  margin-top: 30px;
}
.form-data__title {
  margin: 0px 0px 30px 0px;
}
.ford-data__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-group {
  margin-bottom: 15px;
}

.btn-submit {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-submit:hover {
  background-color: #45a049;
}
.btn-submit:disabled {
  background-color: #cecece;
}
</style>

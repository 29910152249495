<template>
  <div class="reviews__wrap" style="width: 100%">
    <div class="vacancy__item">
      <div class="vacancy__body">
        <!-- Информация об обращении -->
        <div class="" style="border-bottom: 1px solid rgba(124, 141, 181, 0.5);">
          <p class="vacancy__salary text-TTDrugs-regular-24 description__text">
            <span class="bold">Тема обращения:</span> {{ appeal_item.subject }}
          </p>
          <p class="vacancy__salary text-TTDrugs-regular-24 description__text">
            <span class="bold">Имя:</span> {{ appeal_item.fullName }}
          </p>
          <p class="vacancy__salary text-TTDrugs-regular-24 description__text">
            <span class="bold" v-if="appeal_item.createdAt !== null">Дата обращения:</span> {{ appeal_item.createdAt }}
          </p>
        </div>
        <div class="vacancy__block">
          <p class="vacancy__salary text-circe-bold-18"><span class="color-gray bold">Телефон:</span>  {{ appeal_item.phone }}</p>
          <p class="vacancy__salary text-circe-bold-18 "> <span class="color-gray bold">Код:</span> {{ appeal_item.postalCode }}</p>
          <p class="vacancy__salary text-circe-bold-18 "> <span class="color-gray bold">Адрес: </span>{{ appeal_item.address }}</p>
          <p class="vacancy__salary text-circe-bold-18 "> <span class="color-gray bold">Город:</span> {{ appeal_item.city }}</p>
          <p class="vacancy__salary text-circe-bold-18 "> <span class="color-gray bold">Email: </span> {{ appeal_item.email }}</p>
          <p class="vacancy__salary text-circe-bold-18 "><span class="color-gray bold"> Обращение: </span> {{ appeal_item.message }}</p>
        </div>
        <!-- Прикрепленные файлы -->
        <div class="vacancy__block" v-if=" appeal_item.files.length !== 0">
          <p class="vacancy__salary text-circe-bold-18">Прикрепленные файлы:</p>
          <div class="image-block">
            <div v-for="(fileUrl, index) in imageUrls" :key="fileUrl" class="image-container" @click="openCarousel(index)">
              <img :src="fileUrl" alt="Загруженное изображение" />
            </div>
          </div>
        </div>

        <!-- Кнопка удаления -->
        <div>
          <button type="button" class="index-btn index-color" @click="openModalDeleteAppeal(appeal_item)">
            Удалить
          </button>
        </div>
      </div>
    </div>

    <!-- Модальное окно с каруселью изображений -->
    <transition name="fade">
      <div v-if="showCarousel" class="carousel-overlay" @click.self="closeCarousel">
        <button class="carousel-prev" @click.stop="prevImage">⟨</button>
        <button class="carousel-next" @click.stop="nextImage">⟩</button>
        <div class="carousel-content">
          <img :src="imageUrls[currentIndex]" alt="Карусель изображения" />
        </div>
        <button class="carousel-close" @click="closeCarousel"><img src="../../assets/svg/CrossModal.svg" alt=""></button>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { actionTypes as modalActionTypes } from '@/store/modules/modal'
  import axios from 'axios';

  export default {
    name: "AppAppealsItem",
    props: {
      appeal_item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        imageUrls: [], // URL-адреса изображений
        showCarousel: false, // Отображение карусели
        currentIndex: 0 // Текущий индекс изображения
      };
    },
    computed: {
      ...mapState({
        token: (state) => state.auth.token,
      }),
    },
    methods: {
      ...mapActions({
        setModalDeleteAppealsState: modalActionTypes.setIsOpenDeleteAppeals,
      }),
      async loadImages() {
        try {
          const requests = this.appeal_item.files.map((file) =>
              axios.get(file, {
                headers: {
                  Authorization: `Bearer ${this.token}`
                },
                responseType: "blob"
              })
          );

          const responses = await Promise.all(requests);
          this.imageUrls = responses.map((response) => URL.createObjectURL(response.data));
        } catch (error) {
          console.error("Ошибка загрузки изображений:", error);
        }
      },
      openModalDeleteAppeal(item) {
        this.setModalDeleteAppealsState({ isOpen: true, item: item });
      },
      openCarousel(index) {
        this.currentIndex = index;
        this.showCarousel = true;
      },
      closeCarousel() {
        this.showCarousel = false;
      },
      prevImage() {
        if (this.currentIndex > 0) {
          this.currentIndex--;
        } else {
          this.currentIndex = this.imageUrls.length - 1;
        }
      },
      nextImage() {
        if (this.currentIndex < this.imageUrls.length - 1) {
          this.currentIndex++;
        } else {
          this.currentIndex = 0;
        }
      }
    },
    mounted() {
      this.loadImages();
    },
    beforeDestroy() {
      this.imageUrls.forEach((url) => URL.revokeObjectURL(url));
    }
  };
</script>

<style scoped>
.image-block {
  display: flex;
  flex-wrap: wrap;
}
.image-container {
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.image-container img {
  max-width: 200px; /* Ограничение размера для маленьких изображений */
  max-height: 200px;
  object-fit: cover;
}

/* Стили для модального окна карусели */
.carousel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.carousel-content {
  max-width: 90%;
  max-height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-content img {
  width: auto;
  height: auto;
  max-width: 100%; /* Изображение не будет выходить за пределы карусели */
  max-height: 90vh; /* Ограничение по высоте экрана */
  object-fit: contain; /* Сохраняем пропорции изображения */
}
.carousel-prev,
.carousel-next,
.carousel-close {
  position: absolute;
  background: none;
  border: none;
  color: white;
  font-size: 6em;
  cursor: pointer;
  img {
    width: 25px;
    height: 25px;
  }
}
.carousel-prev {
  left: 10px;
}
.carousel-next {
  right: 10px;
}
.carousel-close {
  top: 10px;
  right: 10px;
  font-size: 1.5em;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>

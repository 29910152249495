<template>
  <div class="users">
    <Header
        title="Новости"
        description="Добавление и изменение новостей"
    />
    <div class="wrap-loader-2" v-if="isLoading">
      <div id="loader"></div>
    </div>
    <div class="container-page">
      <div class="users__block">
        <div class="users__wrap">
          <div class="users__add">
            <button
                type="button"
                class="index-btn index-color"
                @click="routePageAdd"
            >
              <img
                  src="../assets/svg/plus.svg"
                  alt="plus"
                  class="index-btn__img"
              />
             Добавить новость
            </button>
          </div>
        </div>
      </div>
      <div class="users-block-people">
        <AppNewsList :news_list="allNews"/>
      </div>
    </div>
    <AppModalDeleteNews  v-if="isOpenModalDeleteNews"
                         :isItemNews="false"
                         @close="closeModalDeleteNews" :item_news="deleteItemNews"/>

  </div>
</template>

<script>
  import Header from "../components/Header.vue";
  import { mapState, mapActions } from "vuex";
  import { actionTypesNews } from '@/store/modules/news'
  import { actionTypes as modalActionTypes } from "@/store/modules/modal";

  import AppModalDeleteNews from '@/components/Modal/ModalDeleteNews.vue'
  import AppNewsList from '@/components/News/NewsList.vue'

  export default {
    components: {
      AppNewsList,
      AppModalDeleteNews,
      Header,
    },
    name: "AppNews",
    data() {
      return {
        searchFIO: "",
        title: "Новости",
      };
    },
    props: {

    },
    methods: {
      ...mapActions({
        setModalDeleteNewsState: modalActionTypes.setIsOpenDeleteNews,
      }),
      routePageAdd() {
        this.$router.push({name: 'AddNews'})
      },
      // openModalAddReview() {
      //   this.openModalAddReviewAction(true);
      // },
      // closeModalAddReview() {
      //   this.openModalAddReviewAction(false);
      // },
      // openModalEditReview(item) {
      //   this.setModalEditReviewState({ isOpen: true, item });
      // },
      // closeModalEditReview() {
      //   this.setModalEditReviewState({ isOpen: false, item: null });
      // },
      openModalDeleteNews(item) {
        this.setModalDeleteNewsState({ isOpen: true, item: item })
      },
      closeModalDeleteNews() {
        this.setModalDeleteNewsState({ isOpen: false, item: null })
      },

    },
    computed: {
      ...mapState({
        allNews: (state) => state.news.allNews,
        isLoading: (state) => state.news.isLoading,
        isOpenModalDeleteNews: (state) => state.modal.isOpenDeleteNews,
        editItemNews: (state) => state.modal.editItemNews,
        deleteItemNews: (state) => state.modal.deleteItemNews,
        token: (state) => state.auth.token,
        username: (state) => state.auth.username,
      }),

    },
    beforeMount() {
      document.title = this.title;
    },
    mounted() {
      if (!this.token) {
        this.$router.push({ name: "Login" });
      }
      this.$store.dispatch(actionTypesNews.getNews);
    },
  };
</script>

import axios from "axios";
import { getItem } from "@/helpers/persistanceStorage";
import router from "@/router";

// axios.defaults.baseURL = "http://109.237.147.106:8080/api/2";
// axios.defaults.baseURL = "http://109.111.185.150:9053/api/2";
axios.defaults.baseURL = "https://15u17control.xn--22-6kcp5d.xn--p1ai/api/2/";
// axios.defaults.baseURL = "https://40c9-103-45-246-79.ngrok-free.app/api/2"

axios.interceptors.response.use(
    (response) => {
      // console.log(response, "response inter");
      if (response.status === 401) {
        alert("You are not authorized");
      }
      return response;
    },
    (error) => {
      console.log(error, "error resp", error.response?.status);
      if (error.response?.status === 401) {
        localStorage.removeItem("accessTokenStoreDar");
        // document.cookie = `accessToken=""; `;
        localStorage.removeItem("infoUserApplicationDar");
        router.push({ name: "Login" });
      }
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
);
axios.interceptors.request.use((config) => {
  const token = getItem("accessTokenStoreDar");

  const authorizationToken = token ? `${token}` : "";
  if (authorizationToken === "") {
    // console.log(config);
  } else {
    config.headers.Authorization = authorizationToken;
  }
  // console.log(config);
  return config;
});


export default axios;

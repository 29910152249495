import axios from "@/api/axios";
const addReview = (data) => {
  return axios.post(`/reviews/save`, data);
};
const getReviews = () => {
  return axios.get(`/reviews`);
};
const deleteReview = (reviewId) => {
  return axios.post(`/reviews/delete?id=${reviewId}`);
};
const editReview = (data) => {
  return axios.post(`/reviews/edit`, data);

};

export default {
  addReview,
  getReviews,
  deleteReview,
  editReview,
};
